/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

export const BtnEcovest = ({
  text = "Become an Eco-Investor. Plant Your Tree Today!",
  stateProp,
  className,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "one-default",
  });
  const navigate = useNavigate();
  const routeChange = () =>{ 
    let path = `/Ecovest/Welcome`; 
    navigate(path);
  } 
  return (
    <div
      className={`btn-ecovest ${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={routeChange}
    >
      <p className="become-an-eco m-0">{text}</p>
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "one-default") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "one-hover",
        };
    }
  }

  if (state.state === "one-hover") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "one-default",
        };
    }
  }

  if (state.state === "two-default") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "two-hover",
        };
    }
  }

  if (state.state === "two-hover") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "two-default",
        };
    }
  }

  return state;
}

BtnEcovest.propTypes = {
  text: PropTypes.string,
  stateProp: PropTypes.oneOf([
    "one-hover",
    "two-default",
    "two-hover",
    "one-default",
  ]),
};
