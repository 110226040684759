import React , { useState , useEffect } from 'react';
import Header from "../../component/header";
import Footer from "../../component/footer";
import Newsgrid from "../../component/newsgrid";
import Data from '../../data/news.json';

  const articles = [
    {
      title: "The Rise of Crowdfunding",
      content: "Investing through crowdfunding platforms has revolutionized how startups raise capital, offering diverse opportunities for small investors to engage in early-stage ventures."
    },
    {
      title: "Sustainable Investments",
      content: "Green finance is gaining traction, with eco-friendly projects attracting investors seeking both profit and environmental impact."
    },
    {
      title: "Equity Crowdfunding Insights",
      content: "Equity crowdfunding allows investors to purchase shares in startups, democratizing access to early-stage investment opportunities."
    },
    {
      title: "Risks and Rewards in Crowdfunding",
      content: "Understanding the balance between potential high returns and inherent risks is crucial for anyone investing through crowdfunding platforms."
    },
    {
      title: "Real Estate Crowdfunding",
      content: "Investing in real estate through crowdfunding offers a unique way to diversify portfolios and gain exposure to property markets without large capital."
    },
    {
      title: "Peer-to-Peer Lending",
      content: "P2P lending platforms connect borrowers directly with investors, offering an alternative to traditional banking and attractive returns for lenders."
    },
    {
      title: "Crowdfunding Regulations",
      content: "Navigating the legal landscape is essential for both investors and entrepreneurs in the crowdfunding space, ensuring compliance and protecting interests."
    },
    {
      title: "Crowdfunding Campaign Success",
      content: "Key strategies for launching a successful crowdfunding campaign include effective marketing, clear communication, and setting realistic goals."
    },
    {
      title: "Technology in Crowdfunding",
      content: "Advancements in technology are enhancing the crowdfunding experience, making it more accessible, secure, and efficient for users worldwide."
    }
  ];
  
   
  
  const Blog = () => {
    const [visibleArticles, setVisibleArticles] = useState(6);
  
    const loadMore = () => {
      setVisibleArticles(prevVisibleArticles => prevVisibleArticles + 6);
    };
    return (
      <div className="App">
         <Header />
         <div className="container">
          <div className="row  mt-3 mb-3">
            <div className="top-tabs d-flex flex-direction-column">
            <ul class="nav nav-pills mb-0 nfaq-tab" id="pills-tab2" role="tablist">
                    <li class="nav-item nfaq-tab-item" role="presentation">
                      <button class="nav-link active nfaq-tab-item-btn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All</button>
                    </li>
                    
                    <li class="nav-item  nfaq-tab-item" role="presentation">
                      <button class="nav-link nfaq-tab-item-btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Finance</button>
                    </li>

                    <li class="nav-item nfaq-tab-item" role="presentation">
                    <button class="nav-link nfaq-tab-item-btn" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Eco Friendly</button>
                  </li>

                    <li class="nav-item nfaq-tab-item"  role="presentation">
                    <button class="nav-link nfaq-tab-item-btn" id="pills-Non-profits-tab" data-bs-toggle="pill" data-bs-target="#pills-Non-profits" type="button" role="tab" aria-controls="pills-Non-profits" aria-selected="false">Non-profits</button>
                  </li>

                    <li class="nav-item nfaq-tab-item"  role="presentation">
                    <button class="nav-link nfaq-tab-item-btn" id="pills-Real-estate-tab" data-bs-toggle="pill" data-bs-target="#pills-Real-estate" type="button" role="tab" aria-controls="pills-Real-estate" aria-selected="false">Real estate</button>
                  </li>

                    <li class="nav-item nfaq-tab-item"  role="presentation">
                    <button class="nav-link nfaq-tab-item-btn" id="pills-Technology-tab" data-bs-toggle="pill" data-bs-target="#pills-Technology" type="button" role="tab" aria-controls="pills-Technology" aria-selected="false">Technology</button>
                  </li>  

                  </ul>
              </div>
             
        </div>
        </div>
         <div className="container mb-3">
        <div className="d-flex flex-wrap post-section justify-content-center">
        {articles.slice(0, visibleArticles).map((article, index) => (
            <div className="col-lg-3 col-sm-12 rounded-4 shadow-lg" key={index}>
                            <img className='rounded-top-4' src="assets/icons-2/post one.png" alt="img" />


              <div className="post-detail p-4">
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              </div>
              </div>
            ))}
          
          
          {visibleArticles < articles.length && (
             <div className="d-flex justify-content-center mt-3">
        <button onClick={loadMore} className="btn btn-primary post-btn">Load More</button>
        </div>
      )}
          </div>
       
          </div>

      <Footer />

      </div>
    );
  
  }
  export default Blog;