import React , { useState , useEffect } from 'react';
import Header from "../../component/dashheader";
import Card from "../../component/property-card";
import Data from "../../data/properties.json";
import { useSelector } from 'react-redux';
import axios from 'axios';
function Profile() {
  const [property,setproperty] = useState([]);
  const [pagination,setpagination] = useState(6);
  const [isHovered, setIsHovered] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveDisabledAddress, setIsSaveDisabledAddress] = useState(true);
  const user = useSelector(state => state.auth.user);
  const { id } = user.claims;
  const { firstName } = user.claims;
  const { lastName } = user.claims;
  const { phoneNumber } = user.claims;
  const investorId = id;
  const investorFirstName = firstName;
  const investorLastName = lastName;
  const investorPhone = phoneNumber;
  const base_Url = process.env.REACT_APP_API_URL;
  const handleMouseEnter = () => {
      setIsHovered(true);
  };

  const handleMouseLeave = () => {
      setIsHovered(false);
  };
  const [address, setAddress] = useState({
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    investorId: investorId
  });
  const [ fullname ] = useState({
    firstName:investorFirstName,
    lastName:investorLastName,
    phone:investorPhone,
  });
  const [investor, setInvestor] = useState({
    firstName:investorFirstName,
    lastName:investorLastName,
    phone:investorPhone,
    birthdayDate: '',
    birthCountry: '',
    nationality: '',
    departmentOfBirth:'',
    gnder:'',
    id: investorId
  });
  const [countries, setCountries] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successInvestor, setSuccessInvestor] = useState(null);
  const [errorInvestor, setErrorInvestor] = useState(null);
  const [successAddress, setSuccessAddress] = useState(null);
  const [errorAddress, setErrorAddress] = useState(null);
  useEffect(() => {
    const { firstName, lastName, phone, birthdayDate } = investor;
    setIsSaveDisabled(!firstName || !lastName || !phone || !birthdayDate);
  }, [investor]);
  useEffect(() => {
    const { countryId, city, postalCode } = address;
    setIsSaveDisabledAddress(!countryId || !city || !postalCode);
  }, [address]);
  useEffect(() => {
    const makedata = Data.filter((index,key) => { return key < 6 } )
  setproperty(makedata)
  const fetchCountries = async () => {
    try {
        const response = await axios.get(`${base_Url}/Country`);
        if (response.status === 200) {
            // Extracting country names and codes for the select options
            const countryList = response.data.data[0].map(country => ({
                name: country.name,
                code: country.id
            }));
            setCountries(countryList);
        } else {
            throw new Error('Failed to fetch countries data');
        }
    } catch (error) {
        setError(error);
    } finally {
        setLoading(false);
    }
  };

  fetchCountries();
  const fetchQuestions = async () => {
    try {
        const response = await axios.get(`${base_Url}/Question`);
        if (response.status === 200) {
            setQuestions(response.data.data[0]);
        } else {
            throw new Error('Failed to fetch questions data');
        }
    } catch (error) {
        setError(error);
    } finally {
        setLoading(false);
    }
  };

  fetchQuestions();
    const fetchAddress = async () => {
        try {
            const response = await axios.get(`${base_Url}/Address/${investorId}`);
            setAddress(response.data.data[0]);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    fetchAddress();
    
    const fetchInveestor = async () => {
      try {
          const response = await axios.get(`${base_Url}/Investor/${investorId}`);
          const data = response.data.data[0];
          const formattedDate = data.birthdayDate.split('T')[0];
          setInvestor({
            ...data,
            birthdayDate: formattedDate, // Set the formatted date
            id: investorId
          });
      } catch (error) {
          setError(error);
      } finally {
          setLoading(false);
      }
    };
    fetchInveestor();

  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
        ...prevAddress,
        [name]: value
    }));
    setInvestor((prevInvestor) => ({
      ...prevInvestor,
      [name]: value
    }));
    setSelectedCountry(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true); // Set loading to true while the request is being made
    setError(null); // Reset any previous errors
    setSuccessAddress(null); // Reset any previous success message
    setErrorAddress(null);
    try {
      const response = await axios.post(`${base_Url}/Address/Create`, address);  
      if (response.status === 200 || response.status === 201) {
        setSuccessAddress('Address updated successfully!');
      } else {
        setErrorAddress('Failed to update address');
      }
    } catch (error) {
      setErrorAddress('Failed to update address');
    } finally {
      setLoading(false);
    }
  }; 
  const handleSubmitInvestor = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true); // Set loading to true while the request is being made
    setError(null); // Reset any previous errors
    setSuccessInvestor(null); // Reset any previous success message
    setErrorInvestor(null);
    try {
      const response = await axios.post(`${base_Url}/Investor/edit`, investor);  
      if (response.status === 200 || response.status === 201) {
        setSuccessInvestor('Investor updated successfully!');
      } else {
        setErrorInvestor('Failed to update investor');
      }
    } catch (error) {
      setErrorInvestor('Failed to update investor');
    } finally {
      setLoading(false);
    }
  }; 
  if (loading) {
    //return HTML OF Loading
  }

  if (error) {
    //return HTML OF Error
  }
  function handleloadmore(num){
    num += 3;
    const makedata = Data.filter((index,key) => { return key < num } )
    setpagination(num)
    setproperty(makedata)
    }
  
  return (
    <div className="App">
       <Header /> 
    <section>
    <div className="container d-flex justify-content-center profile-head" style={{'maxWidth': '1220px'}}>
      <div className="gradiant">
        <img src="assets/icons-2/baner.png" style={{'maxWidth': '1220px'}} />
      </div>
    </div>
    <div className="men d-flex align-items-center" style={{'maxWidth': '1220px','margin-top': '-55px'}}>
      <div className="ms-lg-5 ms-md-5 ms-2 image-cropper">
        <a href='#'><img className={isHovered ? 'd-block' : 'd-none'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id="uploadImage" src="assets/images/uploadImage.png" /></a>
        <img className='d-block' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id="ImageUploaded" src="assets/images/favicon.png" />
      </div>
      <h2>{investor.firstName + ' ' + investor.lastName}</h2>
    </div>
    <div className="nav-tabs-container tabs-section d-flex justify-content-start" style={{'maxWidth': '1220px'}}>
      <ul className="nav nav-tabs d-flex flex-row justify-content-start" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="my-detail-tab" data-bs-toggle="tab" data-bs-target="#my-detail"
            type="button" role="tab" aria-controls="my-detail" aria-selected="true">My details</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="status-tab" data-bs-toggle="tab" data-bs-target="#status" type="button"
            role="tab" aria-controls="status" aria-selected="false">Status</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="investment-tab" data-bs-toggle="tab" data-bs-target="#investment" type="button"
            role="tab" aria-controls="investment" aria-selected="false">Investment</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="Documents-tab" data-bs-toggle="tab" data-bs-target="#Documents" type="button"
            role="tab" aria-controls="Documents" aria-selected="false">Documents</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="Refferals-tab" data-bs-toggle="tab" data-bs-target="#Refferals" type="button"
            role="tab" aria-controls="Refferals" aria-selected="false">Refferals</button>
        </li>
        <li className="nav-item">
          <button className="nav-link" id="My-favorites-tab" data-bs-toggle="tab" data-bs-target="#My-favorites"
            type="button" role="tab" aria-controls="My-favorites" aria-selected="false">My favorites</button>
        </li>
      </ul>
    </div>
    <div className="tab-content profile-main-tabs mb-4 pt-3" id="myTabContent">
      <div className="tab-pane fade show active" id="my-detail" role="tabpanel" aria-labelledby="my-detail-tab">
        <div className="container-fluid">
          <div className="d-flex flex-md-row flex-sm-column flex-column mx-auto w-100">
            <div className="col-md-4 col-sm-12 col-12">
              <h2>Personal information</h2>
              <p>Update your photo and personal details.</p>
            </div>
            <div className="col-md-8 col-sm-12 col-12 my-detail ">
              <form onSubmit={handleSubmitInvestor} className='first-form-profile'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">First name *</label>
                  <input type="text" className="form-control" id="firstName" name="firstName" placeholder="Jhon" 
                  value={investor.firstName} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Last name *</label>
                  <input type="text" className="form-control" id="lastName" name="lastName" placeholder="DOE" 
                  value={investor.lastName} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label for="gender" className="form-label">You are</label>
                  <select className="form-select" id="gender" name="gender"
                  value={investor.gnder} onChange={handleChange}>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label for="phone" className="form-label">Phone number *</label>
                  <input type="number" className="form-control" id="phone" name="phone" placeholder="Phone number" 
                  value={investor.phone} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label for="birthdayDate" className="form-label">Birth Date *</label>
                  <div className='input-group date' id='picker'>
                  <input type='date' className="form-control" name="birthdayDate" id="birthdayDate"
                         value={investor.birthdayDate} onChange={handleChange} required />
                  <span className="input-group-addon">
                    <span className="glyphicon glyphicon-calendar"></span>
                  </span>
                </div>
                </div>
                <div className="mb-3">
                  <label for="birthCountry" className="form-label">Place of birth</label>
                  <select id="birthCountry" name="birthCountry" className="form-select"
                          value={investor.birthCountry} onChange={handleChange} >
                    <option value="">Select a country</option>
                    {countries.map(country => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label for="departmentOfBirth" className="form-label">Department of birth</label>
                  <input type="text" className="form-control" id="departmentOfBirth" name="departmentOfBirth" placeholder="Somewhre else" 
                  value={investor.departmentOfBirth} onChange={handleChange}/>
                </div>
                <div className="mb-3">
                  <label for="nativeCountry" className="form-label">Native country</label>
                  <select id="nativeCountry" name="nationality" className="form-select"
                          value={investor.nationality} onChange={handleChange} >
                    <option value="">Select a country</option>
                    {countries.map(country => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <input type="hidden" name="id" id="id" value={investor.id} onChange={handleChange} />
                <hr className="divider" />
                <div className="button-section">
                  <button type="submit" className="btn btn-primary amj" disabled={isSaveDisabled}>Save Changes</button>
                  {successInvestor && <div className='alert alert-success'>{successInvestor}</div>}
                  {errorInvestor && <div className='alert alert-danger'>{errorInvestor}</div>}
                </div>
              </form>
            </div>
            
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex flex-md-row flex-sm-column flex-column mx-auto w-100 pt-3">
            <div className="col-md-4 col-sm-12 col-12">
              <h2>Tax residence address </h2>
              <p>Update your photo and personal details.</p>
            </div>
            <div className="col-md-8 col-12 my-detail">
              <form onSubmit={handleSubmit} className='first-form-profile'>
                <div className="mb-3">
                  <label for="countryId" className="form-label">Country *</label>
                  <select id="countryId" name="countryId" className="form-select"
                          value={address.countryId} onChange={handleChange} required>
                    <option value="">Select a country</option>
                    {countries.map(country => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label for="city" className="form-label">City *</label>
                  <input type="text" className="form-control" id="city" placeholder="London" name='city'
                         value={address.city} onChange={handleChange} required/>
                </div>
                <div className="mb-3">
                  <label for="postalCode" className="form-label">Zip code *</label>
                  <input type="text" className="form-control" id="postalCode" placeholder="SW1A1AA" name='postalCode'
                         value={address.postalCode} onChange={handleChange} required/>
                </div>
                <div className="mb-3">
                  <label for="state" className="form-label">Region</label>
                  <input type="text" className="form-control" id="state" placeholder="Greator London" name='state'
                         value={address.state} onChange={handleChange}/>
                </div>
                <div className="mb-3">
                  <label for="streetAddress" className="form-label">Address</label>
                  <input type="text" className="form-control" id="streetAddress" placeholder="10 Downing Street" name='streetAddress'
                         value={address.streetAddress} onChange={handleChange}/>
                </div>
                <div className="mb-3">
                  <h3>Marital Status</h3>
                  <label for="disabledSelect" className="form-label">You are</label>
                  <select id="disabledSelect" className="form-select">
                    <option>Single</option>
                    <option>Married</option>
                    <option>Divorced</option>
                    <option>Widowed</option>
                  </select>
                </div>
                <hr className="divider" />
                <input type="hidden" name="investorId" id="investorId" value={address.investorId} onChange={handleChange} />
                <div className="button-section">
                  <button type="submit" className="btn btn-primary amj" disabled={isSaveDisabledAddress}>Save Changes</button>
                  {successAddress && <div className='alert alert-success'>{successAddress}</div>}
                  {errorAddress && <div className='alert alert-danger'>{errorAddress}</div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="tab-pane fade" id="status" role="tabpanel" aria-labelledby="status-tab">
        <div className="container-fluid">
          <div className="d-flex flex-lg-row flex-md-row flex-sm-column flex-column w-100">
            <div className="col-lg-4 col-md-4 col-12">
              <h2>The path to wealth</h2>
              <p className='custom-bg-para'>True wealth is being able to fully enjoy the present moment without being hindered by regrets from the
                past or worries about the future.<br /> <br />___ HSteven
              </p>
              <img className="quote" src="assets/icons-2/quotes 1.png" alt="" />
            </div>
            <div className="owner col-lg-8 col-md-8 col-12">
              {questions.filter(question => question.id === 1).map((question, key) => (
                  <div key={key} className="col-12">
                    <h3>{question.statement}</h3>
                    <div className="head-main row d-flex px-3">
                      {question.answers.map((answer, answerIndex) => (
                        <div key={answerIndex} className="form-check col-lg-6 col-12 mb-3">
                          <div className='d-flex'>
                            <h4 className='col-12 pt-0 pb-3'>{answer.answerTitle}</h4>
                            <input
                              className="form-check-input d-flex align-self-center mb-3"
                              type="radio"
                              name="flexRadioDefault"
                              id={`answer_${answer.id}`}
                            />
                          </div>
                          {answer.description && <p>{answer.description}</p>}
                        </div>
                      ))}
                    </div>
                  </div>
              ))}
              {questions.filter(question => question.id === 2).map((question, index) => (
                  <div key={index} className="col-12">
                  <div className="head-main2 row d-flex px-3">
                    <h2 className='rj-custom-font'>{question.statement}</h2>
                    {question.answers.map((answer, answerIndex) => (
                      <div key={answerIndex} className="form-check col-lg-6 col-12 mb-3">
                        <div className='d-flex'>
                          <h4 className='col-12 pt-0 pb-3'>{answer.answerTitle}</h4>
                          <input
                            className="form-check-input d-flex align-self-center mb-3"
                            type="radio"
                            name="flexRadioDefault2"
                            id={`answer_${answer.id}`}
                          />
                        </div>
                        <label className="form-check-label" htmlFor={`answer_${answer.id}`}></label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {questions.filter(question => question.id === 3).map((question, index) => (
                  <div key={index} className="col-12">
                  <div className="head-main3 row d-flex">
                    <h2 className='rj-custom-font'>{question.statement}</h2>
                    <div className="row">
                      {question.answers.map((answer, answerIndex) => (
                        <div key={answerIndex} className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <label htmlFor={`answer_${answer.id}`}>{answer.answerTitle}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={answer.answerTitle}
                            aria-label={answer.answerTitle}
                            id={`answer_${answer.id}`}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <h4>Preview</h4>
                      <img src="assets/icons-2/chart.png" alt="..." />
                    </div>
                  </div>
                </div>
              ))}
              <hr className="divider" />
              <div className="button-section">
                <button type="submit" className="btn btn-primary amj">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row w-100">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <h2>Income</h2>
              <p className='custom-bg-para'>True wealth is being able to fully enjoy the present moment without being hindered by regrets from the
                past or worries about the future.<br /> <br />___ HSteven
              </p>
              <img className="quote" src="assets/icons-2/quotes 1.png" alt="" />
            </div>
            <div className="gros col-lg-8 col-md-8 col-sm-12 col-12">
            {questions.filter(question => question.id === 4).map((question, index) => (
                  <div key={index} className="col-12">
                  <h3>{question.statement}</h3>
                  <div className="col-12 estimated">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="placeholder"
                      aria-label="Last name"
                    />
                  </div>
                </div>
            ))}
            {questions.filter(question => question.id === 5).map((question, index) => (
                  <div key={index} className="col-12">
                    <div className="head-main3 row d-flex">
                      <h2 className='rj-custom-font'>{question.statement}</h2>
                      <div className="row rj-row1">
                        {question.answers.map((answer, answerIndex) => (
                          <div key={answerIndex} className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label htmlFor={`answer_${answer.id}`}>{answer.answerTitle}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="%"
                              aria-label={answer.answerTitle}
                              id={`answer_${answer.id}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
            ))}
            {questions.filter(question => question.id === 6).map((question, index) => (
                  <div key={index} className="head-main3 col-12">
                    <div className="col-12 Saving">
                      <h2 className='rj-custom-font'>{question.statement}</h2>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="placeholder"
                          aria-label="Last name"
                        />
                      </div>
                    </div>
                  </div>
            ))}
            {questions.filter(question => question.id ===7).map((question, index) => (
                  <div key={index} className="col-12">
                    <h2 className='rj-custom-font'>{question.statement}</h2>
                    <div className="row d-flex px-3">
                      {question.answers.map((answer, answerIndex) => (
                        <div key={answerIndex} className="form-check col-lg-6 col-12 mb-3">
                          <div className='d-flex'>
                            <h4 className='col-12 pt-0 pb-3'>{answer.answerTitle}</h4>
                            <input
                              className="form-check-input d-flex align-self-center mb-3"
                              type="radio"
                              name="flexRadioDefault3"
                              id={`answer_${answer.id}`}
                            />
                          </div>
                          <label className="form-check-label" htmlFor={`answer_${answer.id}`}></label>
                        </div>
                      ))}
                    </div>
                  </div>
            ))}
            {questions.filter(question => question.id === 8).map((question, index) => (
                  <div key={index} className="col-12">
                    <h3>{question.statement}</h3>
                    <div className="col-12 estimated">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="placeholder"
                        aria-label="Last name"
                      />
                    </div>
                  </div>
            ))}
            {questions.filter(question => question.id === 9).map((question, index) => (
                  <div key={index} className="col-12">
                    <div className="head-main3 row d-flex">
                      <h2 className='rj-custom-font'>{question.statement}</h2>
                      <div className="row">
                        {question.answers.map((answer, answerIndex) => (
                          <div key={answerIndex} className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label htmlFor={`answer_${answer.id}`}>{answer.answerTitle}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="%"
                              aria-label={answer.answerTitle}
                              id={`answer_${answer.id}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
            ))}
            {questions.filter(question => question.id === 10).map((question, index) => (
                  <div key={index} className="col texas">
                  <h2>Texas</h2>
                  <h4>{question.statement}</h4>
                  <label htmlFor="taxAmount"></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="placeholder"
                    id="taxAmount"
                  />
                </div>
              ))}
              <hr className="divider" />
              <div className="button-section">
                <button type="submit" className="btn btn-primary amj">Save Changes</button>
              </div>

            </div>
          </div>
        </div>
        </div>
          <div className="tab-pane fade" id="investment" role="tabpanel" aria-labelledby="investment-tab">
           
            <div className="container-fluid d-flex ">
            <div className="row w-100">
            <div className="col-4 ">
              <h2>Investment</h2> 
              <p className="custom-bg-para">It's important to know all of your options.By definition, real estate investing is a high-stakes game. Never make an investment that you are unsure of. It is critical to understand what you are doing in order to succeed.<br /> <br />Tips to Be Successful in Real Estate Investing
              </p>
            </div>
            <img className="quote" src="assets/icons-2/quotes 2.png" alt="" />
            <div className="col-8 option ">
              <h2 className="option-c-h2">Have you ever invested in real estate?</h2>
              <div className="options">
                  <input className="form-check-input" type="radio" name="estate" id="estateYes" />
                  <label className="form-check-label" for="estateYes">
                      Yes
                  </label>
                  <input className="form-check-input" type="radio" name="estate" id="estateNo" checked />
                  <label className="form-check-label" for="estateNo">
                      No
                  </label>
              </div>

              
              <div className="col options">
                <h4>What type of investment</h4>
                <div >
                  <select id="disabledSelect" className="form-select">
                    <option>Mutual funds</option>
                    <option>Mutual funds</option>
                  </select>
                </div>
              </div>

              
              <div className="options">
                <h3>The amount of investment you plan to
                  does it represent 10% of your total assets? *</h3>
                <input className="form-check-input" type="radio" name="plan" id="planYes" />
                <label className="form-check-label">
                  Yes
                </label>
                <input className="form-check-input" type="radio" name="plan" id="planNo" checked />
                <label className="form-check-label">
                  No
                </label>
              </div>



            
              <div className="options rj-col-invest">
                <div className="col investment">
                  <h4>What type of real estates ?</h4>
                  <div >
                    <select id="disabledSelect" className="form-select">
                      <option>Commercial Real Estate</option>
                      <option>Commercial Real Estate</option>
                    </select>
                  </div>
                </div>
                <h4>Do you intend to invest in the next 12 months? *</h4>
                <input className="form-check-input" type="radio" name="intend" id="intendYes" />
                <label className="form-check-label">
                  Yes
                </label>
                <input className="form-check-input" type="radio" name="intend" id="intendNo" checked />
                <label className="form-check-label">
                  No
                </label>
              </div>
              <hr className="divider" />
              <div className="button-section">
                <button type="submit" className="btn btn-primary amj">Save Changes</button>
              </div>
            </div>
          </div>
         </div>
        </div> 

          <div className="tab-pane fade" id="Documents" role="tabpanel" aria-labelledby="Documents-tab">
            <div className="container-fluid">
           <div className="row w-100 Documents-tab">
            <div className="col-4">
              <h2>Documents</h2> 
              <p>This space is yours.Your documents will<br /> be  treated with seriousness and in <br />complete security.
              </p>
            </div>
            <div className="col-8 drag-section">
              <h3>Document name *</h3>
               <div className="input_form" >
                <img src="assets/icons-2/Button.png" alt="..." />
                <h4><span>Click to upload </span>or Drag and Drop <br /> PDF, PNG, ou JPG</h4>
               </div>
               <hr className="divider" />
              <div className="button-section">
                <button type="submit" className="btn btn-primary amj">Save Changes</button>
              </div>
               </div>
               
            </div>
           </div>
          </div>
        

          <div className="tab-pane fade" id="Refferals" role="tabpanel" aria-labelledby="Refferals-tab">
            <div className="container-fluid rj-ref-cont">
              <div className="row w-100 refferals-sec">
                <div className="col refferal">
                  <h2>Bonus</h2>
                  <h3>Total bonus collected: <span><img src="assets/icons-2/Tannit coin.png" alt="" />245</span></h3>  
                  <div className="claim d-flex align-items-center">
                    <h2>Not Claimed:</h2>
                    <h3>You have <span className="fiftyseven">57 TANNIT</span> from <span className="four"> 4 activites</span> done by your refferals</h3>
                    <button>Claim now</button>
                  </div>
                </div>
              </div>
            </div>
             
            <div className="container-fluid rj-ref-cont">
            <div className="row w-100 refferals-sec">
              <div className="col refferal">
                <h3>You were brought on this platform by: <span className="men-sec"><img src="assets/icons-2/men img.png" />Guy Hawkins</span></h3>  
                <div className="claim d-flex align-items-center">
                  <h2>Your refferal ID:</h2>
                  <div className="min-sec">
                    www.tannit-compte.com
                    <span><img src="assets/icons-2/Icon.png" alt="" /></span>
                  </div>
                </div>
                <p>This is the ID you have to give to the investors you bring on the TANNIT platform. The referred investor has to enter this ID as referral ID when they register. You will receive bonuses when he makes activities.</p>
              </div>
            </div>
          </div>

          <div className="container-fluid table-con ">
            <div className="row w-100 table-sec-pro">
              <div className="col">
            <table className="table mv2-tab-main ">
              <thead>
                  <tr>
                      <th scope="col">Date joined</th>
                      <th scope="col">user</th>
                      <th scope="col"></th>
                      <th scope="col">Invested amount</th>
                      <th scope="col">bonus (TANNIT)</th>
                  </tr>
              </thead>
              <tbody className="tsp-body">
                  <tr>
                      <td scope="row">13/02/2023</td>
                      <td className="img-text-td"><span> <img src="assets/icons-2/img1.png"alt="" /></span> Guy Hawkins</td>
                      <td className="chart-td"></td>
                      <td>57£</td>
                      <td>24</td>
                  </tr>

                  <tr>
                      <td scope="row">15/02/2023</td>
                      <td className="img-text-td"><span> <img src="assets/icons-2/img2.png"alt="" /></span> Guy Hawkins</td>
                      <td className="chart-td"></td>
                      <td>233£</td>
                      <td>24</td>
                  </tr>

                  <tr>
                      <td scope="row">27/02/2023</td>
                      <td className="img-text-td"><span> <img src="assets/icons-2/img3.png"alt="" /></span> Guy Hawkins</td>
                      <td className="chart-td"></td>
                      <td>153£</td>
                      <td>24</td>
                  </tr>

                  <tr>
                      <td scope="row">29/02/2023</td>
                      <td className="img-text-td"><span> <img src="assets/icons-2/img4.png"alt="" /></span> Guy Hawkins</td>
                      <td className="chart-td"></td>
                      <td>33£</td>
                      <td>24</td>
                  </tr>
              </tbody>
          </table>
        </div>
        </div>

          </div>


          </div>

          <div className="tab-pane fade" id="My-favorites" role="tabpanel" aria-labelledby="My-favorites-tab">

            <div className="container-fluid">
              <div className="mb-3">
                <div className="result-text2 d-flex spb-btn">
                    <p>Showing<strong>115 property</strong> that match your search criteria</p>
                    <button className="mp-btn">Sort by (Date)<span><img src="assets/icons/updown.svg"
                                alt="" /></span></button>

                </div>
            </div>
              <div className="row row-cols-1 row-cols-md-3 g-4 mb-5 amj-ppage justify-content-between">
             

            {
              property.map((index,key) =>(
                <>
                  <Card key={key} index={index} />
                </>
              ))
            }
            </div>
            </div>
          </div>

    </div>
    </section>
    </div>  
    );
  }
  
  export default Profile;
