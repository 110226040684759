import React , { useState , useEffect } from 'react';
import Header from "../../component/dashheader";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
function Myinvestment() {

    const [property,setproperty] = useState([]);
    const [pagination,setpagination] = useState(6);
    const [propertyCount, setPropertyCount] = useState(0);
    const [myPropertyCount, setMyPropertyCount] = useState(0);
    const [propertyListCount, setPropertyListCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const base_Url = process.env.REACT_APP_API_URL;
    const user = useSelector(state => state.auth.user);
    const { id } = user.claims || {};
    const investorId = id && id.toString();

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true);
            try {
              const response = await axios.get(base_Url + '/Property/MyProperties?start=0&pageSize=' + pagination + '&investorId=' + investorId);
              setproperty(response.data.data[0]);
              setPropertyListCount(response.data.data[0].length);
              console.log(response.data.data[0]);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
            finally {
                setLoading(false);
            }
          };
          fetchProperties();
          const fetchPropertiesCount = async () => {
            setLoading(true);
            try {
              const count = await axios.get(base_Url + '/Property/Count');
              setPropertyCount(count.data.data[0]);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
            finally {
                setLoading(false);
            }
          };
          fetchPropertiesCount();
          fetchPropertiesCount();
          const fetchMyPropertiesCount = async () => {
            setLoading(true);
            try {
              const count = await axios.get(base_Url + '/Property/Count/' + investorId);
              setMyPropertyCount(count.data.data[0]);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
            finally {
                setLoading(false);
            }
          };
          fetchMyPropertiesCount();
    },[pagination]);


    const handleloadmore = () => {
        setpagination(prevPagination => prevPagination + 3);
    };

    return (
      <div className="App">
         <section>
         <Header />

<div className="container-fluid artical-cont " style={{'maxWidth': '1440px'}}>
    <div className="row artical1">
            <div className="tab-btns d-flex justify-content-center my-4 gap-2"> 
                <a href='/Properties' className="btn-5 p-tab2">{'Properties (' + propertyCount + ')'}</a>
                <a href='/Myinvestment' className="btn-5 p-tab">{'My investments (' + myPropertyCount + ')'}</a>
            </div>
        <div className="show-res my-">
            <div className="result-text d-flex">
                <p>Showing <strong>{myPropertyCount} {myPropertyCount > 1 ? 'properties' : 'property'}</strong></p>
            </div>
            <div className="date-btn">Sort by (Date) <img src="assets/icons/date.svg" alt="" /></div>
        </div>
    </div>
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-5">
        {
                    property.map((item,key) => ( 
                        <div key={key} className="col">
                            <Link to={`/property-details-sale/${item.propertyId}`} className="text-decoration-none">

                            <div className="card h-100">
                                <div id={'image' + item.propertyId} className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        {item.images.map((image, imageIndex) => (
                                            <button
                                                key={imageIndex}
                                                type="button"
                                                data-bs-target={'#image' + item.propertyId}
                                                data-bs-slide-to={imageIndex}
                                                className={imageIndex === 0 ? "active" : ""}
                                                aria-current={imageIndex === 0 ? "true" : "false"}
                                                aria-label={'Slide ' + image.imageId}>
                                            </button>
                                        ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {item.images.map((image, imageIndex) => (
                                            <div
                                                key={imageIndex}
                                                className={`carousel-item ${imageIndex === 0 ? "active" : ""}`}>
                                                {image.type == 'image' ? <img src={image.url} className="d-block w-100 propertyImage" style={{'border-radius':'16px 16px 0px 0px'}} alt="..." />
                                                 : <video className="d-block w-100 propertyImage" controls style={{'border-radius':'16px 16px 0px 0px'}}>
                                                 <source src={image.url} type="video/mp4" /></video>}
                                                
                                                <button className="image-btn">
                                                    <img src="assets/icons/leaf.svg" alt="" />Eco Friendly
                                                </button>
                                                <button className="image-btn-4">
                                                    <img src="assets/icons/hleaf.svg" alt="" />125 <img src="assets/icons/up.svg " alt="" />0.2%
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="2btn-grp">
                                    {item.tags.map((tag, tagIndex) => (
                                        <div key={tagIndex} className={(tag.tagId != 16 && tag.tagId != 17) ? 'd-none' : 'tag'}>
                                            {(tag.tagId == 16 || tag.tagId == 17) ? <button className="global-ct">{tag.label}</button> : <div className='d-none'></div>}
                                        </div>
                                    ))}
                                    {item.InvestorPropertyId > 0 ?
                                        <button className="global-ct-sec"><span> <img src="assets/icons/grn-tick.svg" alt="" /> </span> Contributed</button>
                                        :
                                        ''
                                    }
                                </div>
                                    <h5 className="p-card-title">{item.propertyName}</h5>
                                    <div className='d-flex align-items-center mb-3'>
                                    <img src="assets/icons/gb 1.svg" alt="" className='me-2' />
                                    <p className="card-text">
                                        {item.address.streetAddress + ', ' + item.address.city + ' ' + item.address.postalCode}
                                    </p>
                                    </div>
                                    <div className='d-flex'>
                                    {item.tags.map((tag, tagIndex) => (
                                        <div key={tagIndex} className={(tag.tagId < 11 && tag.tagId > 15) ? 'd-none' : 'tag'}>
                                            {(tag.tagId >= 11 && tag.tagId <= 15) ? <button className="inline-svg-icons-btn1 me-2"><i className={tag.fontAwesomeId}></i></button> : <div className='d-none'></div>}
                                        </div>
                                    ))}
                                    </div>
                                    <div>
                                        <div className="card-inner">
                                            <div className="amount">{(item.price / 1000) + 'K £'}</div>
                                            <div className="remain"> <img src="assets/icons/Tannit coin.svg" alt="" />{((item.price /10) - item.propertyInfos.tannitInvested) + ' remaining'}
                                            </div>
            
                                        </div>
                                        <div className="card-inner2">
                                            <div className="amount-text">{'Converted to: ' + (item.price /10) + ' Tannits'}</div>
                                            <div className="investor"> <img src="assets/icons/Icon2.svg" alt="" />{item.propertyInfos.nbrInvestors + ' Investors'}</div>
            
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{'width': ((((item.price / 10) - ((item.price /10) - item.propertyInfos.tannitInvested)) / (item.price / 10)) * 100) + '%'}} aria-valuenow="75"
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div className="card-inner3 invi my-4 ">
                                            <div className="profit d-flex pf2">Invested 
                                                <p>{item.propertyInfos.myTannitInvested} Tannit</p>
                                            </div>
                                            {item.tags.filter(tag => tag.tagId === 6).map((tag, tagIndex) => (
                                                <div key={tagIndex} className="returned d-flex rf2">
                                                    Income received 
                                                    <p className="g-text">1.45£**</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        ))
        }
    </div>
        <div className="row justify-content-center mb-5">
        {propertyListCount < myPropertyCount?
                <button onClick={handleloadmore} className="bw-learn btn btn-show" type="search" disabled={loading}>
                    {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                        'Show more'
                    )}
                </button>
                :<div></div>
            }    
        </div>
    </div>
        

</section>
      </div>     
    );
  }
  
  export default Myinvestment;