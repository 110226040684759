import React from "react";
import { BtnEcovest } from "../../component/ecovestonboarding/BtnEcovest";
import { Carousel } from "../../component/ecovestonboarding/Carousel";
import "./style.css";

const EcovestOnboarding = () => {
  return (
    <div className="w-100 onboardingcontainer">
      <div className="ecovest-onboarding mx-auto">
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="overlap-2">
              <div className="rectangle" />

              <div className="rectangle-2" />

              <div className="group">
                <div className="overlap-group-2">
                  <img
                    className="ellipse-5"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-315.svg"
                  />

                  <img
                    className="ellipse-6"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-316.svg"
                  />

                  <img
                    className="ellipse-7"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-317.svg"
                  />

                  <img
                    className="ellipse-8"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-318.svg"
                  />

                  <img
                    className="ellipse-9"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-319.svg"
                  />

                  <img
                    className="ellipse-10"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-320.svg"
                  />

                  <img
                    className="ellipse-11"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-321.svg"
                  />

                  <img
                    className="ellipse-12"
                    alt="Ellipse"
                    src="/assets/images/ecovestonboarding/ellipse-322.svg"
                  />
                </div>
              </div>

              <div className="frame-2">
                <div className="playful-engaging">Playful &amp; Engaging</div>

                <p className="text-wrapper">
                  At Tannit, we believe in investing that makes a difference
                </p>
              </div>

              <img className="img-2" alt="Vector" src="/assets/images/ecovestonboarding/vector.svg" />

              <img className="img-2" alt="Mask group" src="/assets/images/ecovestonboarding/mask-group.png" />

              <img className="image" alt="Image" src="/assets/images/ecovestonboarding/image-212.png" />

              <img className="image-2" alt="Image" src="/assets/images/ecovestonboarding/image-213.png" />

              <img
                className="green-beautiful-tree"
                alt="Green beautiful tree"
                src="/assets/images/ecovestonboarding/green-beautiful-tree-isolated-white-background-generative-ai-1.png"
              />

              <div className="rectangle-3" />

              <div className="frame-3">
                <div className="frame-4">
                  <div className="container-wrapper">
                    <div className="container justify-content-start">
                      <img
                        className="green-coin"
                        alt="Green coin"
                        src="/assets/images/ecovestonboarding/green-coin.svg"
                      />

                      <div className="balance">
                        <div className="text-wrapper-2">8K XP</div>

                        <div className="frame-5">
                          <img
                            className="image-3"
                            alt="Image"
                            src="/assets/images/ecovestonboarding/image-86.png"
                          />

                          <div className="text-wrapper-3">3</div>
                        </div>

                        <div className="frame-wrapper">
                          <div className="frame-6" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-7">
                    <p className="p">Grow Your Forest, One Action at a Time!</p>

                    <p className="earning-the-right-to">
                      <span className="span">
                        Earning the right to plant trees in the Ecovest forest is
                        easy and rewarding.{" "}
                      </span>

                      <span className="text-wrapper-4">
                        Here&#39;s how it works:
                      </span>
                    </p>
                  </div>
                </div>

                <div className="frame-8">
                  <div className="frame-9">
                    <p className="text-wrapper-5">
                      ⭐&nbsp;&nbsp;Engage &amp; Earn Green Coins:
                    </p>

                    <p className="text-wrapper-6">
                      Earn Green Coins for daily logins, social media shares,
                      sponsoring others, and most importantly, investing in
                      sustainable real estate projects on Tannit.
                    </p>
                  </div>

                  <div className="frame-9">
                    <div className="text-wrapper-5">
                      🚀&nbsp;&nbsp;Level Up with ExperiencE:
                    </div>

                    <p className="as-you-interact-with">
                      As you interact with the platform, you&#39;ll gain
                      experience points (XP). Each level unlocks the ability to
                      plant a specific tree type in the virtual forest.
                    </p>
                  </div>

                  <div className="frame-9">
                    <div className="text-wrapper-5">
                      🧭&nbsp;&nbsp;Track Your Progress:
                    </div>

                    <p className="text-wrapper-7">
                      A handy progress bar shows you how close you are to reaching
                      the next level.
                    </p>
                  </div>

                  <div className="frame-10">
                    <div className="text-wrapper-5">
                      🌲&nbsp;&nbsp;Plant Your Trees:
                    </div>

                    <p className="text-wrapper-8">
                      Once you reach a new level, the number of trees you can
                      plant will be displayed next to the tree icon. Simply access
                      the Ecovest virtual forest to plant your well-deserved
                      trees!
                    </p>
                  </div>
                </div>
              </div>

              <div className="frame-11">
                <div className="frame-12">
                  <img
                    className="logo-mark"
                    alt="Logo mark"
                    src="/assets/images/ecovestonboarding/logo-mark.png"
                  />

                  <div className="text-wrapper-9">what’s Tannit Ecovest?</div>
                </div>

                <p className="text-wrapper-10">
                  Invest sustainably, level up your impact.
                </p>

                <p className="text-wrapper-11">
                  At Tannit, we believe in creating a positive impact that starts
                  with your choices. Introducing Ecovest, a unique program where
                  your real estate investments and platform interactions cultivate
                  a virtual forest and contribute to tangible environmental
                  change.
                </p>

                <BtnEcovest
                  className="btn-ecovest-instance"
                  stateProp="two-default"
                  text="Plant tree for impact"
                />
              </div>

              <img className="image-4" alt="Image" src="/assets/images/ecovestonboarding/image-214.png" />
            </div>

            <BtnEcovest
              className="design-component-instance-node"
              stateProp="two-default"
              text="Plant tree for impact"
            />
          </div>
        </div>

        <footer className="footer">
          <img className="logo-footer" alt="Logo" src="/assets/images/ecovestonboarding/logo-2.svg" />
          <p className="text-wrapper-12">
            Lorem ipsum dolor sit amet consectetur. Viverra suscipit senectus
            lorem facilis facilisi iaculis potenti massa euismod accumsan. Dictum
            pharetra
          </p>

          <img className="line" alt="Line" src="/assets/images/ecovestonboarding/line-2.svg" />

          <p className="text-wrapper-13">@ 2024 All rights reserved</p>

          <p className="condition-of-use">
            Condition of use &amp; Privacy Policy
          </p>

          <div className="frame-13">
            <div className="text-wrapper-14">Contact Us</div>

            <div className="text-wrapper-15">2035, Sousse , Sousse</div>

            <div className="text-wrapper-15">info@gmail.com</div>

            <div className="text-wrapper-15">+212 55 564 654</div>

            <div className="social-media">
              <i className="fa-brands fa-square-facebook icon-instance-node text-white"></i>
              <i className="fa-brands fa-linkedin icon-instance-node text-white"></i>
              <i className="fa-brands fa-square-google-plus icon-instance-node text-white"></i>
            </div>
          </div>
        </footer>

        <div className="overlap-group-wrapper">
          <div className="div-wrapper">
            <div className="frame-14">
              <div className="frame-15">
                <img className="logo-2" alt="Logo" src="/assets/images/ecovestonboarding/logo-1.svg" />

                <div className="frame-16">
                  <div className="text-wrapper-16">Unlock Your Eco-Power</div>

                  <div className="in-steps"> in 4 Steps</div>
                </div>
              </div>

              <Carousel property1="default" />
              <BtnEcovest
                className="btn-ecovest-instance"
                stateProp="one-default"
                text="Sign Up &amp; Join the Movement!"
              />
            </div>
          </div>
        </div>

        <div className="hero-section">
          <div className="overlap-3">
            <div className="element-tiny">
              <video width="1439" height="811" autoPlay loop muted>
                <source src="https://cdn.pixabay.com/video/2018/10/02/18534-293472901_large.mp4" type="video/mp4"/>
              </video>
            </div>

            <div className="rectangle-4">
              
            </div>

            <div className="frame-17">
              <div className="frame-18">
                <p className="text-wrapper-17">
                  Grow your investments, grow a greener world !
                </p>

                <p className="text-wrapper-18">
                  Welcome to Tannit Ecovest, the virtual forest where your green
                  investments cultivate real change. Join the movement and plant
                  virtual trees as you invest in sustainable real estate.
                </p>
              </div>

              <div className="btn-ecovest-wrapper">
                <BtnEcovest
                  className="btn-ecovest-instance"
                  stateProp="one-default"
                  text="Become an Eco-Investor. Plant Your Tree Today!"
                />
              </div>
            </div>

            <img className="logo-3" alt="Logo" src="/assets/images/ecovestonboarding/logo-2.svg" />

            <div className="ellipse-13" />

            <div className="number-trees-planted">
              <div className="overlap-group-3">
                <div className="ellipse-14" />

                <img className="pikpng" alt="Pikpng" src="/assets/images/ecovestonboarding/pikpng-4-1.png" />

                <div className="frame-19">
                  <div className="text-wrapper-19">3719</div>

                  <div className="planted-trees-until">
                    planted trees
                    <br />
                    until now !
                  </div>
                </div>

                <img
                  className="pikpng-2"
                  alt="Pikpng"
                  src="/assets/images/ecovestonboarding/pikpng-3-1.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EcovestOnboarding;