import {React,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../component/authanticationheader";
function BuyPropertySuccess() {
    const navigate = useNavigate();

    const handleGoToMarketplace = () => {
        navigate('/Marketplace');
    };
    useEffect(() => {
        document.title = 'Opertation success'; // Set the page title on component mount
        return () => {
          document.title = 'Tannit'; // Reset the page title when component unmounts
        };
      }, []); // Empty dependency array ensures this effect runs only on mount and unmount
    return (
        <div className="App">
            <Header />
            <div>

                <section className="main-grid2 d-flex">
                    <div className="container">
                        <div className="row">
                            <div className="middle-sec2">
                                <img src="assets/images/flowers.png" alt="" />
                                <h2>Property invested successfully!</h2>
                                <p>You have successfully invest to property. </p>

                            </div>
                            <div className="claim-last-sec2">

                                <button className="btn grn-btn3" onClick={handleGoToMarketplace}>
                                    Go to marketPlace <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default BuyPropertySuccess;
