import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const initialState = {
    transactions: [],
    transactionStats: null,
    status: 'idle',
    statsStatus: 'idle',
    error: null,
    statsError: null,
};

export const fetchTransactions = createAsyncThunk(
    'transactions/fetchTransactions',
    async ({ start, pageSize, investorId }) => {
        const response = await axios.get(`${apiUrl}/Transaction/list?start=${start}&pageSize=${pageSize}&investorId=${investorId}`);
        return response.data.data[0];
    }
);

export const fetchTransactionStats = createAsyncThunk(
    'transactions/fetchTransactionStats',
    async (investorId) => {
        const response = await axios.get(`${apiUrl}/Transaction/Statistics?investorId=${investorId}`);
        return response.data.data[0];
    }
);

export const purchaseProperty = createAsyncThunk(
    'transactions/purchaseProperty',
    async ({ investorId, propertyId, AmountTannit, Description }) => {
        const response = await axios.post(`${apiUrl}/Transaction/AddPurchaseTransaction`, {
            investorId,
            propertyId,
            AmountTannit,
            Description,
            CreatedAt: new Date().toISOString()
        });
        return response.data;
    }
);

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchTransactionStats.pending, (state) => {
                state.statsStatus = 'loading';
            })
            .addCase(fetchTransactionStats.fulfilled, (state, action) => {
                state.statsStatus = 'succeeded';
                state.transactionStats = action.payload;
            })
            .addCase(fetchTransactionStats.rejected, (state, action) => {
                state.statsStatus = 'failed';
                state.statsError = action.error.message;
            })
            .addCase(purchaseProperty.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(purchaseProperty.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions.push(action.payload);
            })
            .addCase(purchaseProperty.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default transactionsSlice.reducer;
