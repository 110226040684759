import React from 'react';
import Header from "../../component/header";
import Footer from "../../component/footer";
const TermsOfUse = () => {
  return (
    <div className="mt-3">
             <Header />
    <div className="terms-of-use container">
    <div className="top-banner d-flex justify-content-center flex-wrap align-content-center">
        <h1 className="banner-hd">Terms of Use</h1>
        </div>
      <p>Updated on April 9, 2024</p>
      <p>
        These Terms of Use are agreed upon between: Tannit, on the one hand, And the user accessing the Site (hereinafter "User"), on the other hand,
      </p>

      <h2>Article 1 - Definitions</h2>
      <ul>
        <li><strong>Collection:</strong> Refers to a crowdfunding offer proposed by a Project Owner under the conditions of Articles L. 223-6, L. 411-2 2° b), and Article L. 511-6 7° of the Monetary and Financial Code ("CMF").</li>
        <li><strong>Personal Space:</strong> The private area of the Site, accessible only to the Investor individually.</li>
        <li><strong>Investor:</strong> A User who has registered on the Site but has not completed their investor profile and does not have the ability to subscribe to the Collections offered on the Site.</li>
        <li><strong>Approved Investor:</strong> An Investor who has completed the client suitability questionnaires, provided the required documents, and has been accepted by Tannit and the Banking Partner. An Approved Investor can subscribe to the Collections offered on the Site through the Electronic Wallet managed by the Banking Partner.</li>
        <li><strong>Site:</strong> Refers to the progressive access website where Users can view and Investors can consult available crowdfunding Projects and subscribe to them, provided they have the status of Approved Investor. This area is accessible at tannit.uk.</li>
        <li><strong>Banking Partner:</strong> Refers to Lemonway, our payment institution authorized by the Prudential Control and Resolution Authority (ACPR) under number 16568.</li>
        <li><strong>Electronic Wallet:</strong> The e-wallet or online payment account assigned to the Approved Investor upon their first Subscription and managed by the Banking Partner.</li>
        <li><strong>Project Owner:</strong> Refers to a Project Owner whose project Tannit has agreed to propose on the Site through a Collection.</li>
        <li><strong>Subscriber:</strong> An Approved Investor subscribing to a Collection on the Site.</li>
        <li><strong>User:</strong> Refers to an Internet user who browses the Tannit.uk website but has not registered as an Investor.</li>
      </ul>

      <h2>Article 2 - Purpose, Information, and Services Offered on the Site</h2>
      <p>
        The Tannit.uk Site aims to allow investors to participate in the financing of real estate Projects proposed by Project Owners. Tannit is a crowdfunding site authorized as a European Crowdfunding Service Provider (ECSP) by the Financial Markets Authority under number FP-2023-08, in accordance with Regulation (EU) 2020/1503 on European crowdfunding service providers.
      </p>
      <p>
        Tannit uses the services of a Payment Service Provider (the "Banking Partner") authorized in France and listed among the institutions authorized to operate in France, as published on the website www.acpr.banque-france.fr. The Banking Partner within Tannit is Lemonway.
      </p>
      <p>
        Registration and acceptance of these Terms of Use allow Users to register on the Site and complete their "Investor" profile. Access to certain information and services on the Site is subject to registration as an Investor and the creation of a Personal Space.
      </p>
      <p>
        These Terms of Use may be supplemented by specific conditions presented to the User for acceptance before accessing certain information and services. Any registration on the Site and any browsing imply unconditional acceptance of these Terms of Use. Tannit reserves the right to modify these Terms of Use at any time. The User must refer to the latest version of the Terms of Use online at the date of their consultation. In case of modification of the Terms of Use, the User receives a notification when browsing the Site with the new Terms of Use. If not engaged in a financing operation on the Site and wishes to refuse them, the User has 5 working days to notify Tannit. Beyond this period, Tannit will consider that the User has tacitly accepted the new Terms of Use.
      </p>
      <p>
        The Terms of Use of the Banking Partner may also be modified at any time, including in case of a change of Banking Partner. The User must refer to the latest version of the Banking Partner's Terms of Use in effect at the date of their consultation, which will automatically be the latest version of said Terms of Use. The Terms of Use of our current Banking Partner, Lemonway, are accessible at: <a href="https://www.lemonway.com/terms-of-use">Lemonway Terms of Use</a>.
      </p>

      <h2>Article 3 - Access and Registration Conditions on the Site</h2>
      <p>
        The Site accessible at tannit.uk is reserved for Users aged 18 and over who have the legal capacity to contract.
      </p>
      <p>
        Access to the information and services on the Site is progressive and subject to prior registration as an Investor under the conditions described in Articles 4 and 5 of these Terms of Use.
      </p>
      <p>
        Registration as an Investor requires the provision of accurate, complete, and up-to-date personal information in accordance with Article 7 of these Terms of Use.
      </p>
      <p>
        The User agrees to maintain the accuracy of this information by updating their Personal Space if necessary.
      </p>
      <p>
        Tannit reserves the right to deny access to the Site and the services offered to Users who do not comply with these Terms of Use or who do not meet the registration criteria.
      </p>

      <h2>Article 4 - User Status</h2>
      <p>
        The Site tannit.uk is a progressive access site with several steps before allowing a User to become an Approved Investor and subscribe to a Collection.
      </p>
      <p>
        Users can thus have a progressive status, depending on their activity on the Site, which will be defined in the following order: User, Investor, Approved Investor, Subscriber, as specified in the "Definitions" article.
      </p>
      <p>
        The attainment of each status is subject to specific conditions defined in these Terms of Use. Users acknowledge and agree that their status may be revoked in case of non-compliance with these Terms of Use or any other legal or regulatory provision in force.
      </p>
      <p>
        Tannit also reserves the right to modify the conditions for obtaining each status at any time and without notice.
      </p>

      <h2>Article 5 - Withdrawal Period</h2>
      <p>
        Under Article L. 341-16 of the Monetary and Financial Code, there is a right of withdrawal of 4 full calendar days from the Subscription Date. This right can be exercised without having to justify reasons or pay penalties.
      </p>
      <p>
        The User can invest in real estate projects presented on the site and benefits from this right of withdrawal. The Site must provide the user with a "Cancel Investment" button that allows them to exercise their right of withdrawal from their Personal Space. If the user decides to withdraw, they must inform the site via this button or any other means provided by the site. The refund of the amounts paid is immediate on the User's Electronic Wallet.
      </p>
    </div> 
    <Footer />
    </div>
  );
};

export default TermsOfUse;
