import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactions, fetchTransactionStats } from '../../features/transactions/transactionsSlice';
import Header from "../../component/dashheader";
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Wallettwo() {
    const dispatch = useDispatch();
    const transactions = useSelector(state => state.transactions.transactions);
    const transactionStats = useSelector(state => state.transactions.transactionStats) || {};
    useEffect(() => {
        document.title = 'My wallet'; // Set the page title on component mount
        return () => {
          document.title = 'Tannit'; // Reset the page title when component unmounts
        };
      }, []); // Empty dependency array ensures this effect runs only on mount and unmount
    const TotalTannitVestedValue = transactionStats.totalTannitVested || 0;
    const TotalTannitWithdrownValue = transactionStats.totalTannitWithdrown || 0;
    const TotalTannitPurchedValue = transactionStats.totalTannitPurched || 0;
    const TotalTannitnoneVestedValue = transactionStats.totalTannitnoneVested || 0;

    const status = useSelector(state => state.transactions.status);
    const statsStatus = useSelector(state => state.transactions.statsStatus);
    const error = useSelector(state => state.transactions.error);
    const statsError = useSelector(state => state.transactions.statsError);
    const user = useSelector(state => state.auth.user);
    const { id: investorId } = user.claims;
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    useEffect(() => {
        if (investorId) {
            dispatch(fetchTransactions({ start: 1, pageSize: 10, investorId }));
            dispatch(fetchTransactionStats(investorId));
        }
    }, [dispatch, investorId]);

    const filteredTransactions = transactions.filter(transactions => {
        const transactionDate = new Date(transactions.createdAt);
        const start = startDate ? new Date(startDate) : new Date('1900-01-01');
        const end = endDate ? new Date(endDate) : new Date();
        return (
            (transactions.description && transactions.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
            transactionDate >= start && transactionDate <= end
        );
    });
    const currentBalance = TotalTannitVestedValue - TotalTannitWithdrownValue - TotalTannitPurchedValue;
    const currentBalanceGBP = currentBalance * 10; 

    const processingValue = TotalTannitnoneVestedValue;
    const processingValueGBP = processingValue * 10;
   //chart
   const [data, setData] = useState([]);
   const [labels, setLabels] = useState([]);
 useEffect(() => {
   const filterAndProcessTransactions = () => {
     const topupTransactions = transactions.filter(transaction => transaction.description === "Top-up transaction");
   

     const lastSixMonthsLabels = getLastSixMonths();
   
   // Process the data
   const processedData = processData(topupTransactions, lastSixMonthsLabels);
     setLabels(lastSixMonthsLabels);
     setData(processedData);
   };

   filterAndProcessTransactions();
 }, [transactions]);

 const getLastSixMonths = () => {
   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
   const result = [];
   const date = new Date();
   for (let i = 5; i >= 0; i--) {
     const d = new Date(date.getFullYear(), date.getMonth() - i, 1);
     result.push(months[d.getMonth()]);
   }
   return result;
 };

 const processData = (transactions, labels) => {
   const data = Array(labels.length).fill(0);
 
   transactions.forEach(transaction => {
 
     const transactionDate = new Date(transaction.createdAt);
     if (isNaN(transactionDate.getTime())) {
       return;
     }
 
     const month = transactionDate.toLocaleString('default', { month: 'short' });
     const monthIndex = labels.indexOf(month);
 
     if (monthIndex >= 0) {
       // Use the correct field for amount
       const amount = Number(transaction.tannitsTopped);
       if (isNaN(amount)) {
         console.warn("Invalid amount format:", transaction.tannitsTopped);
       } else {
         data[monthIndex] += amount; // Ensure amount is a number
       }
     } else {
       console.warn("Month not found in labels:", month);
     }
   });
 
   return data;
 };


 const chartData = {
   labels: labels,
   datasets: [
     {
       label: 'Topup Transactions',
       data: data,
       borderColor: 'rgba(75, 192, 192, 1)',
       backgroundColor: 'rgba(75, 192, 192, 0.2)',
       fill: false,
     },
   ],
 };
 const options = {
   responsive: true,
   plugins: {
     legend: {
       display: false,
     },
     tooltip: {
       callbacks: {
         label: function (context) {
           return `${context.raw.toFixed(3)}`;
         },
       },
     },
   },
   scales: {
     x: {
       display: true,
     },
     y: {
       display: true,
     },
   },
 };
 //end chart

    return (
        <div className="App">
            <Header />
            <section className="wallet tabs container">
                <div className="container wal-cont">
                    <div className="row wt-p-rj">
                        <div className="col-3 left-main">
                            <h3 className="bal-head"><span><img src="assets/icons/walllet.svg" alt="" /></span>Current balance</h3>
                            <h3 className="bal-head2"><span><img src="assets/images/Tannit coin.png" alt="" /></span>{currentBalance}</h3>
                            <p>= {currentBalanceGBP}£</p>
                        </div>
                        <div className="col-2 second-left-main">
                            <h3 className="bal-head"><span><img src="assets/icons/clock3.svg" alt="" /></span>Processing</h3>
                            <h3 className="bal-head2">{processingValue}</h3>
                            <p>= {processingValueGBP}£</p>
                        </div>
                        <div className="col-lg-4 second-right-main">
                        <>
      {data.every(amount => amount === 0) ? (
              <div className="col-12 middle-main">
                <h3 className="bal-head"><i class="fa fa-money me-2" aria-hidden="true"></i>Current cash</h3>
                <h3 className="bal-head2">0£</h3>
              </div>
      ) : (
        <Line data={chartData} options={options} />
      )}
    </>
                        </div>
                        <div className="col right-main">
                        <Link to="/addmoney">
                                <button>Get TANNIT</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet-tabs2 container">
                <div className="container wal-cont2">
                    <div className="row wt-p-rj">
                    <div class="col-4 1st-tab"><div class="boat-card"><svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 0 120 120" width="120"><path fill="#fff" d="M66.45 26.74l19.24 51.31H27.97l38.48-51.31z"></path><path fill="#f4f4f4" d="M66.45 26.74L40.8 78.05H27.97l38.48-51.31z"></path><path d="M66.45 85.46c-.55 0-1-.45-1-1V26.74c0-.55.45-1 1-1s1 .45 1 1v57.72c0 .55-.45 1-1 1z" fill="#dcdcdc"></path><path d="M85.69 78.89H27.97c-.38 0-.73-.21-.9-.55a.998.998 0 0 1 .1-1.05l38.48-51c.22-.29.58-.44.94-.39a1 1 0 0 1 .79.64l19.24 51a.997.997 0 0 1-.93 1.35zm-55.71-2h54.27L66.16 28.94 29.98 76.89zM107.55 91.87c-4.47 0-8.21-2.09-9.62-3-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3-5.58 0-10.03-3.27-10.22-3.41-.44-.33-.53-.95-.21-1.4.33-.44.96-.54 1.4-.21.04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01a.996.996 0 0 1 1.4.21c.33.44.24 1.07-.21 1.4-.19.14-4.64 3.41-10.22 3.41zM85.25 85.46H31.82c-.55 0-1-.45-1-1s.45-1 1-1h53.42c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#dcdcdc"></path><g fill="#dcdcdc"><path d="M83.7 84.01l-3 5.99M79.815 89.569l2.993-5.984 1.788.895-2.992 5.984z"></path></g><path fill="#dcdcdc" d="M34.77 85.09l1.555-1.259 2.938 3.63-1.554 1.258z"></path></svg><p>Not Implemented</p></div></div>
                    <div class="col-4 1st-tab"><div class="boat-card"><svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 0 120 120" width="120"><path fill="#fff" d="M66.45 26.74l19.24 51.31H27.97l38.48-51.31z"></path><path fill="#f4f4f4" d="M66.45 26.74L40.8 78.05H27.97l38.48-51.31z"></path><path d="M66.45 85.46c-.55 0-1-.45-1-1V26.74c0-.55.45-1 1-1s1 .45 1 1v57.72c0 .55-.45 1-1 1z" fill="#dcdcdc"></path><path d="M85.69 78.89H27.97c-.38 0-.73-.21-.9-.55a.998.998 0 0 1 .1-1.05l38.48-51c.22-.29.58-.44.94-.39a1 1 0 0 1 .79.64l19.24 51a.997.997 0 0 1-.93 1.35zm-55.71-2h54.27L66.16 28.94 29.98 76.89zM107.55 91.87c-4.47 0-8.21-2.09-9.62-3-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3-5.58 0-10.03-3.27-10.22-3.41-.44-.33-.53-.95-.21-1.4.33-.44.96-.54 1.4-.21.04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01a.996.996 0 0 1 1.4.21c.33.44.24 1.07-.21 1.4-.19.14-4.64 3.41-10.22 3.41zM85.25 85.46H31.82c-.55 0-1-.45-1-1s.45-1 1-1h53.42c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#dcdcdc"></path><g fill="#dcdcdc"><path d="M83.7 84.01l-3 5.99M79.815 89.569l2.993-5.984 1.788.895-2.992 5.984z"></path></g><path fill="#dcdcdc" d="M34.77 85.09l1.555-1.259 2.938 3.63-1.554 1.258z"></path></svg><p>Not Implemented</p></div></div>
                    </div>
                </div>
            </section>
            <section className="wallet-tabs3 container">
                <div className="container wal-table-form">
                <div className="d-flex flex-md-row flex-column">
            <div className="form-group col-md-3 mb-3">
                <input
                    type="date"
                    placeholder="Start Date"
                    className="form-control"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                />
            </div>
            <div className="form-group col-md-3 mb-3">
                <input
                    type="date"
                    placeholder="End Date"
                    className="form-control"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                />
            </div>
            <div className="form-group col-md-3 mb-3">
                <div className="icon-input d-flex justify-content-center">
                    <input
                        className="icon-input__text-field sec"
                        type="text"
                        placeholder="Search for activity"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <i className="fa fa-search mag pe-1"></i>
                </div>
            </div>
        </div>
        <div className="col-12 wv2-table">
            {status === 'loading' && <div>Loading...</div>}
            {status === 'failed' && <div>Error: {error}</div>}
            {status === 'succeeded' && (
                <div className="table-responsive">
                    <table className="table main-rj-dt">
                        <thead>
                            <tr>
                                <th scope="col">DATE <span><img src="assets/icons/Font awesomedate.svg" alt=""/></span></th>
                                <th scope="col">Details</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Tannit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransactions.length === 0 ? (
                                <tr>
                                    <td colSpan="4">No data to display</td>
                                </tr>
                            ) : (
                                filteredTransactions.map((transactions, index) => (
                                    <tr key={index}>
                                        <td scope="row">{new Date(transactions.createdAt).toLocaleDateString()}</td>
                                        <td>{transactions.description}</td>
                                        <td className={transactions.description.includes('Purchase') ? 'color-td-red' : 'color-td-grn'}>
                                            {transactions.price}£
                                        </td>
                                        <td>{transactions.tannitsTopped || 'N/A'}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
                </div>
                <div className="">
                    <div className="col-12 wv2-total">
                        <div>
                            <h3>Total</h3>
                        </div>
                        <div className="multi-data-head d-flex">
                            <h3>Total Credit:</h3>
                            <p>0.00£</p>
                        </div>
                        <div className="multi-data-head d-flex">
                            <h3>Total debit:</h3>
                            <p className="p2-t">0.00£</p>
                        </div>
                        <div className="multi-data-head d-flex flex-md-row flex-column align-items-start">
                            <h3>Net profit:</h3>
                            <p>+ 0.00</p>
                            <button className="total-btn1">Withdraw</button>
                        </div>
                    </div>
                </div>
                <div className="container total-multi-tab-sec d-flex">
                <div class="col-4 1st-tab"><div class="boat-card"><svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 0 120 120" width="120"><path fill="#fff" d="M66.45 26.74l19.24 51.31H27.97l38.48-51.31z"></path><path fill="#f4f4f4" d="M66.45 26.74L40.8 78.05H27.97l38.48-51.31z"></path><path d="M66.45 85.46c-.55 0-1-.45-1-1V26.74c0-.55.45-1 1-1s1 .45 1 1v57.72c0 .55-.45 1-1 1z" fill="#dcdcdc"></path><path d="M85.69 78.89H27.97c-.38 0-.73-.21-.9-.55a.998.998 0 0 1 .1-1.05l38.48-51c.22-.29.58-.44.94-.39a1 1 0 0 1 .79.64l19.24 51a.997.997 0 0 1-.93 1.35zm-55.71-2h54.27L66.16 28.94 29.98 76.89zM107.55 91.87c-4.47 0-8.21-2.09-9.62-3-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3-5.58 0-10.03-3.27-10.22-3.41-.44-.33-.53-.95-.21-1.4.33-.44.96-.54 1.4-.21.04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01a.996.996 0 0 1 1.4.21c.33.44.24 1.07-.21 1.4-.19.14-4.64 3.41-10.22 3.41zM85.25 85.46H31.82c-.55 0-1-.45-1-1s.45-1 1-1h53.42c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#dcdcdc"></path><g fill="#dcdcdc"><path d="M83.7 84.01l-3 5.99M79.815 89.569l2.993-5.984 1.788.895-2.992 5.984z"></path></g><path fill="#dcdcdc" d="M34.77 85.09l1.555-1.259 2.938 3.63-1.554 1.258z"></path></svg><p>Not Implemented</p></div></div>
                <div class="col-4 1st-tab"><div class="boat-card"><svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 0 120 120" width="120"><path fill="#fff" d="M66.45 26.74l19.24 51.31H27.97l38.48-51.31z"></path><path fill="#f4f4f4" d="M66.45 26.74L40.8 78.05H27.97l38.48-51.31z"></path><path d="M66.45 85.46c-.55 0-1-.45-1-1V26.74c0-.55.45-1 1-1s1 .45 1 1v57.72c0 .55-.45 1-1 1z" fill="#dcdcdc"></path><path d="M85.69 78.89H27.97c-.38 0-.73-.21-.9-.55a.998.998 0 0 1 .1-1.05l38.48-51c.22-.29.58-.44.94-.39a1 1 0 0 1 .79.64l19.24 51a.997.997 0 0 1-.93 1.35zm-55.71-2h54.27L66.16 28.94 29.98 76.89zM107.55 91.87c-4.47 0-8.21-2.09-9.62-3-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3-5.58 0-10.03-3.27-10.22-3.41-.44-.33-.53-.95-.21-1.4.33-.44.96-.54 1.4-.21.04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01a.996.996 0 0 1 1.4.21c.33.44.24 1.07-.21 1.4-.19.14-4.64 3.41-10.22 3.41zM85.25 85.46H31.82c-.55 0-1-.45-1-1s.45-1 1-1h53.42c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#dcdcdc"></path><g fill="#dcdcdc"><path d="M83.7 84.01l-3 5.99M79.815 89.569l2.993-5.984 1.788.895-2.992 5.984z"></path></g><path fill="#dcdcdc" d="M34.77 85.09l1.555-1.259 2.938 3.63-1.554 1.258z"></path></svg><p>Not Implemented</p></div></div>
                <div class="col-4 1st-tab"><div class="boat-card"><svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 0 120 120" width="120"><path fill="#fff" d="M66.45 26.74l19.24 51.31H27.97l38.48-51.31z"></path><path fill="#f4f4f4" d="M66.45 26.74L40.8 78.05H27.97l38.48-51.31z"></path><path d="M66.45 85.46c-.55 0-1-.45-1-1V26.74c0-.55.45-1 1-1s1 .45 1 1v57.72c0 .55-.45 1-1 1z" fill="#dcdcdc"></path><path d="M85.69 78.89H27.97c-.38 0-.73-.21-.9-.55a.998.998 0 0 1 .1-1.05l38.48-51c.22-.29.58-.44.94-.39a1 1 0 0 1 .79.64l19.24 51a.997.997 0 0 1-.93 1.35zm-55.71-2h54.27L66.16 28.94 29.98 76.89zM107.55 91.87c-4.47 0-8.21-2.09-9.62-3-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3s-8.21-2.09-9.62-3c-1.41.91-5.15 3-9.62 3-5.58 0-10.03-3.27-10.22-3.41-.44-.33-.53-.95-.21-1.4.33-.44.96-.54 1.4-.21.04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01c.35-.26.84-.26 1.19 0 .04.03 4.12 3.01 9.02 3.01s8.98-2.98 9.02-3.01a.996.996 0 0 1 1.4.21c.33.44.24 1.07-.21 1.4-.19.14-4.64 3.41-10.22 3.41zM85.25 85.46H31.82c-.55 0-1-.45-1-1s.45-1 1-1h53.42c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#dcdcdc"></path><g fill="#dcdcdc"><path d="M83.7 84.01l-3 5.99M79.815 89.569l2.993-5.984 1.788.895-2.992 5.984z"></path></g><path fill="#dcdcdc" d="M34.77 85.09l1.555-1.259 2.938 3.63-1.554 1.258z"></path></svg><p>Not Implemented</p></div></div>
                </div>
            </section>
        </div>
    );
}

export default Wallettwo;
