import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
const apiUrl = process.env.REACT_APP_API_URL;
// Retrieve user from localStorage
const storedUser = JSON.parse(localStorage.getItem('user')) || {};

// Initialize claims from localStorage if available
const initialClaims = storedUser.claims || null;

const initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem('authenticated')) || false,
  user: {
    ...storedUser,
    claims: initialClaims
  },
};


// Async thunk to check and create investor
export const checkAndCreateInvestor = createAsyncThunk(
  'auth/checkAndCreateInvestor',
  async (_, { getState }) => {
    const state = getState();
    const decodedToken = state.auth.decodedToken;

    if (decodedToken) {
      try {
        // Check if investor exists
        const response = await axios.get(`${apiUrl}/investor/${decodedToken.id}`);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Create new investor
          await axios.post(`${apiUrl}/Investor/Create`, null, {
            params: {
              id: decodedToken.id
            }
          });
        } else {
          console.error('Error checking investor existence:', error);
          throw error; // rethrow the error to handle it in the outer catch
        }
      }
      
    }
  }
);
export const getSharesByProperty = createAsyncThunk(
  'auth/getSharesByProperty',
  async ({ propertyId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/investor/GetShares`, {
        params: {
          investorId: userId, 
          propertyId: propertyId
        }
      });

      // Assuming the response contains the shares data
      return response.data.data[0];
    } catch (error) {
      console.error('Error fetching shares:', error);
      
      // Handling error more explicitly
      if (error.response && error.response.data) {
        // Return a rejected value that can be caught in extraReducers
        return rejectWithValue(error.response.data);
      } else {
        // For other kinds of errors (network issues, etc.)
        return rejectWithValue('Something went wrong while fetching shares');
      }
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = !!action.payload;
      state.decodedToken = jwtDecode(action.payload.access_token); // Decode the token
      state.user = {
        ...action.payload,
        claims: {
          email: state.decodedToken.Email,
          firstName: state.decodedToken.firstName,
          lastName: state.decodedToken.lastName,
          id: state.decodedToken.id,
          phoneNumber: state.decodedToken.phoneNumber
        }
      };
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('authenticated', JSON.stringify(state.isAuthenticated));
    },
    clearUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.decodedToken = null;
      localStorage.removeItem('user');
      localStorage.removeItem('authenticated');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAndCreateInvestor.fulfilled, (state, action) => {
    });
    builder.addCase(checkAndCreateInvestor.rejected, (state, action) => {
      console.error('Check and create investor rejected:', action.error);
    });
    builder.addCase(getSharesByProperty.fulfilled, (state, action) => {
      state.shares = action.payload; // Update state with the shares
    });
    builder.addCase(getSharesByProperty.rejected, (state, action) => {
      console.error('Fetching shares failed:', action.error);
    });
  },
});

export const { setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;
