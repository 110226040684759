import { UserManager } from 'oidc-client-ts';
const identityUrl = process.env.REACT_APP_IDENTITY_URL;
const config = {
    authority: identityUrl,
    client_id: 'react-client',
    redirect_uri: 'https://tannit.uk/callback',
    responseType: 'code',
    scope: 'openid profile',
    PostLogoutRedirectUris : 'https://tannit.uk/callbackLogout',
  };
  const userManager = new UserManager(config);

  const userService = {
    signinRedirect: () => userManager.signinRedirect(),
    signinRedirectCallback: () => userManager.signinRedirectCallback(),
    signoutRedirect: () => userManager.signoutRedirect(),
    getUser: () => userManager.getUser(),
  };
  
  export default userService;