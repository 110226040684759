import React from "react";
import { InstanceTooltip } from "../../component/ecovestwelcome/InstanceTooltip";
import { NotifBreathing } from "../../component/ecovestwelcome/NotifBreathing";
import { PlantingTree } from "../../component/ecovestwelcome/PlantingTree";
import { Tree } from "../../component/ecovestwelcome/Tree";
import { useNavigate } from 'react-router-dom';
import "./style.css";

const WelcomeScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-screen mx-auto">
      <div className="overlap">
        <div className="map-container">
          <div className="overlap-group">
            <div className="map-sizes">
              <img
                className="snazzy-image"
                alt="Snazzy image"
              />
            </div>

            <div className="trees">
              <Tree className="tree-instance" property1="default" />
              <Tree
                className="design-component-instance-node"
                property1="default"
              />
              <Tree className="tree-2" property1="default" />
              <Tree className="tree-3" property1="default" />
              <Tree className="tree-4" property1="default" />
              <Tree className="tree-5" property1="default" />
              <Tree className="tree-6" property1="default" />
              <Tree className="tree-7" property1="default" />
              <Tree className="tree-8" property1="default" />
              <Tree className="tree-9" property1="default" />
              <Tree className="tree-10" property1="default" />
              <Tree className="tree-11" property1="default" />
              <Tree className="tree-12" property1="default" />
              <Tree className="tree-13" property1="default" />
              <Tree className="tree-14" property1="default" />
              <Tree className="tree-15" property1="default" />
              <Tree className="tree-16" property1="default" />
              <Tree className="tree-17" property1="default" />
              <Tree className="tree-18" property1="default" />
              <Tree className="tree-19" property1="default" />
              <Tree className="tree-20" property1="default" />
              <Tree className="tree-21" property1="default" />
              <Tree className="tree-22" property1="default" />
              <Tree className="tree-23" property1="default" />
              <Tree className="tree-24" property1="default" />
              <Tree className="tree-25" property1="default" />
              <Tree className="tree-26" property1="default" />
              <Tree className="tree-27" property1="default" />
              <Tree className="tree-28" property1="default" />
              <Tree className="tree-29" property1="default" />
              <Tree className="tree-30" property1="default" />
              <Tree className="tree-31" property1="default" />
              <Tree className="tree-32" property1="default" />
              <Tree className="tree-33" property1="default" />
              <Tree className="tree-34" property1="default" />
              <Tree className="tree-35" property1="default" />
              <Tree className="tree-36" property1="default" />
              <Tree className="tree-37" property1="default" />
              <Tree className="tree-38" property1="default" />
              <Tree className="tree-39" property1="default" />
              <Tree className="tree-40" property1="default" />
              <Tree className="tree-41" property1="default" />
            </div>

            <div className="filter">
              <div className="div">Show my trees only</div>
            </div>

            <div className="frame">
              <div className="table-row">
                <div className="frame-2" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">8K XP</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>

              <div className="table-row">
                <div className="frame-4" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">9K XP</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>

              <div className="table-row">
                <div className="frame-5" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">14K XP</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>

              <div className="table-row">
                <div className="frame-6" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">14K XP</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>

              <div className="table-row">
                <div className="frame-7" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">14K XP</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>

              <div className="table-row">
                <div className="frame-8" />

                <div className="frame-3">
                  <div className="user">
                    <div className="text-wrapper-2">Guy Hawkins</div>

                    <div className="div-wrapper">
                      <div className="text-wrapper-3">164 trees</div>
                    </div>
                  </div>

                  <div className="text-wrapper-4">#Save the earth</div>
                </div>
              </div>
            </div>

            <div className="button">
              <i class="fa fa-plus icons-font-awesome-instance" aria-hidden="true"></i>
              <img className="divider" alt="Divider" src="/assets/images/ecovestwelcomeimg/divider.svg" />
              <i className="fa fa-minus icons-font-awesome-instance" aria-hidden="true"></i>
            </div>

            <p className="p">346,648 Planted trees (all forests)</p>

            <div className="text-wrapper-5">248,004 people participated</div>

            <a className="earn-XP" href="/Ecovest/EarnXp">
              <i className="fa fa-star-o icons-font-awesome-2 text-black" aria-hidden="true"></i>
              <div className="text-wrapper-6">Earn XP</div>

              <NotifBreathing
                breathing="out"
                className="notif-breathing-animation"
              />
            </a>

            <div className="current-XP">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestwelcomeimg/green-coin.svg"
              />

              <div className="text-wrapper-6">9K XP</div>
            </div>

            <div className="leaderboard">
              <i className="fa-solid fa-ranking-star icons-font-awesome-2"></i>
              <div className="text-wrapper-7">Leaderboard</div>
            </div>

            <div className="tour-again">
              <i className="fa-solid fa-question icons-font-awesome-3 me-2"></i>
              <div className="div">Tour</div>
            </div>
          </div>
        </div>

        <button className="go-back-button" onClick={() => navigate(-1)}>
          <i class="fa fa-arrow-left icons-font-awesome-4 text-black" aria-hidden="true"></i>
          <img className="logo" alt="Logo" src="/assets/images/ecovestwelcomeimg/logo.svg" />

          <div className="text">{""}</div>
        </button>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <a href="/Ecovest/PlantingTree" className="button-2">
          <div className="text-wrapper-8">Plant a tree</div>

          <InstanceTooltip
            className="instance-tooltip-instance"
            override={
              <PlantingTree
                className="planting-tree-available-trees"
                image="/assets/images/ecovestwelcomeimg/image-86-1.png"
              />
            }
            stateProp="default"
            toolTipDirectionTopClassName="instance-tooltip-3"
            toolTipIndicatorClassName="instance-tooltip-2"
            toolTipTooltipContent="3 eco-friendly saplings are available for planting."
            tooltipPosition="bottom"
          />
        </a>
      </div>
      <div className="container ms-5">
        <div className="city-info">
          <div className="city-name">Manchester</div>

          <div className="trees-planted">140/140 trees planted</div>
        </div>

        <div className="city-info">
          <div className="city-name-2">Nottingham</div>

          <div className="trees-planted-2">320/400 trees planted</div>
        </div>

        <div className="city-info">
          <div className="city-name">Liverpool</div>

          <div className="trees-planted">20/640 trees planted</div>
        </div>

        <div className="city-info">
          <div className="city-name">Leeds</div>

          <div className="trees-planted">20/640 trees planted</div>
        </div>

        <div className="city-info">
          <div className="city-name-3">Sheffield</div>

          <div className="trees-planted">coming soon</div>
        </div>

        <div className="city-info">
          <div className="city-name">Cambridge</div>

          <div className="trees-planted">coming soon</div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeScreen;