import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setAuthenticated, checkAndCreateInvestor } from './features/auth/authSlice';
import userService from './userService';
import axios from 'axios';

const Callback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const user = await userService.signinRedirectCallback();

        // Ensure the user object contains the expected tokens
        if (!user || !user.access_token || !user.id_token || !user.profile) {
          throw new Error('Invalid user object returned from callback');
        }

        // Extract only serializable data
        const serializableUser = {
          profile: user.profile,
          id_token: user.id_token,
          access_token: user.access_token,
        };

        dispatch(setUser(serializableUser));
        await dispatch(checkAndCreateInvestor()); // Check and create investor
        localStorage.setItem('id_token', user.id_token);
        localStorage.setItem('user_info', JSON.stringify(user.profile));
        window.location.href = '/dashboard';
      } catch (error) {
        console.error('Callback error:', error);
      }
    };

    handleCallback();
  }, [dispatch]);

  return (
    <div>
      <h1>Processing login...</h1>
    </div>
  );
};

export default Callback;
