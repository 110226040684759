import Header from "../../component/header";
import Contact from "../../component/contactForm";
import Footer from "../../component/footer";


function home() {
    return (
      <div className="App">
        <Header />
        <div className="mb-3">
          <Contact />
        </div>
        <Footer />
      </div>
    );
  }
  
  export default home;
  