import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ percentage }) => {
  const circleStyle = {
    strokeDasharray: `${percentage}, 100`,
  };

  return (
    <div className="progress-wrapper">
      <svg className="progress-ring" width="60" height="60">
        <CircularProgressbar className='progress-ring__circle' value={percentage} />
      </svg>
      <img
        src="/assets/images/favicon.png"
        alt="profile"
        className="profile-image"
      />
    </div>
  );
};

export default CircularProgressBar;