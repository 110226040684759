import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import { useLocation } from 'react-router-dom';
import { getSharesByProperty } from '../../features/auth/authSlice';

function Sellingshare() {
  const location = useLocation();
  const [tannitAmount, setTannitAmount] = useState(40);
  const [variation, setVariation] = useState(10);
  const [loading, setLoading] = useState(false); // Loading state for the API call
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((state) => state.auth.user);
  const shares = useSelector((state) => state.auth.shares); // Access shares from the state
  const { id: userId } = user.claims;
  const searchParams = new URLSearchParams(location.search);
  const propertyId = searchParams.get('propertyId');

  // Fetch shares when the component mounts
  useEffect(() => {
    if (propertyId) {
      dispatch(getSharesByProperty({ propertyId, userId }));
    }
  }, [dispatch, propertyId]);

  // Update tannitAmount when shares data is available
  useEffect(() => {
    console.log(shares);
    if (shares) {
      setTannitAmount(shares); // Use the fetched shares as tannitAmount
    }
  }, [shares]);

  // Function to handle manual input for Tannit amount
  const handleTannitAmountChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= shares) {
      setTannitAmount(value);
    }
  };

 // Function to handle manual input for Variation
const handleVariationChange = (event) => {
  const value = event.target.value; // Get the raw input value as a string
  const numericValue = parseFloat(value); // Try converting it to a number
  
  // Allow the user to type negative values and ensure the number is between -20 and 20
  if (!isNaN(numericValue) && numericValue >= -20 && numericValue <= 20) {
    setVariation(numericValue); // Only update the state if it's a valid number
  } else if (value === '-' || value === '') {
    // Allow empty input and single minus sign for typing a negative number
    setVariation(value);
  }
};


  // Function to increment/decrement Tannit amount
  const changeTannitAmount = (change) => {
    setTannitAmount((prevAmount) => {
      const newAmount = prevAmount + change;
      return newAmount >= 0 && newAmount <= shares ? newAmount +"%" : prevAmount+"%" ;
    });
  };

  // Function to increment/decrement Variation
  const changeVariation = (change) => {
    setVariation((prevVariation) => {
      const newVariation = prevVariation + change;
      return newVariation >= -20 && newVariation <= 20 ? newVariation : prevVariation;
    });
  };

  const handleSell = async () => {
    setLoading(true); // Show loading while making the API call
   
    const transactionRequest = {
      AmountTannit: tannitAmount,
      AskingPrice: askingPrice,
      Status: "sold",
      InvestorId: userId,
      PropertyId: propertyId,
      SellingPercentage: variation,
    };

    try {
      const response = await axios.post(`${apiUrl}/Transaction/AddForSaleTransaction`, transactionRequest);

      if (response.status === 201 || response.status === 200) {
        navigate('/marketplace');
      } else {
        console.error('Failed to add transaction:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop loading after the API call
    }
  };
  const askingPrice = tannitAmount * 10 * (1 + variation / 100).toFixed(2); // Calculate the asking price
  const profit = askingPrice -tannitAmount * 10 ;
  const profitStyle = {
    color: profit < 0 ? 'red' : 'green'
  };
  const handleReturn = () => {
    navigate('/Myinvestment');
  };

  return (
    <div className="App">
      <section className="main-sel-comp" style={{ maxWidth: '720px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="sel-m-head">My contribution</h3>
              <div className="shead-box">
                <p className="multic-head">Invested amount<span>{shares} Tannit</span></p>
                <p className="multic-head2">Income received<span>1.45£</span></p>
              </div>
              <h3 className="sel-m-head">Amount for sell</h3>
              <div className="col-12 not-sec2">
                <p>Amount of TANNIT desired</p>
                <div className="input-group counter-form mb-3">
                  <span className="input-group-text counter-form-cont" onClick={() => changeTannitAmount(-1)}>-</span>
                  <input
                    type="number"
                    className="form-control counter-form-input"
                    value={tannitAmount}
                    onChange={handleTannitAmountChange}
                    style={{
                      backgroundImage: `url("/assets/icons/Tannit coin.svg")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 10px center',
                      paddingRight: '40px',
                    }}
                  />
                  <span className="input-group-text counter-form-cont" onClick={() => changeTannitAmount(1)}>+</span>
                </div>
                <label className="form-label note2">Note: 1 TANNIT = 10£</label>
              </div>
              <h3 className="sel-m-head">Selling price</h3>
              <div className="col-12 not-sec2">
                <p>Variation</p>
                <div className="input-group counter-form mb-3">
                  <span className="input-group-text counter-form-cont" onClick={() => changeVariation(-0.01)}>-</span>
                  <input
  type="text"
  className="form-control counter-form-input"
  value={variation.toString()}// Display the raw variation value
  onChange={handleVariationChange}
  onBlur={() => setVariation(parseFloat(variation) || 0)} // Apply final formatting with percentage sign on blur
/>
              <span className="input-group-text counter-form-cont" onClick={() => changeVariation(0.01)}>+</span>
                </div>
                <div className="org-text">
                  <span><img src="assets/icons/yelow.svg" alt="" /></span> You can't exceed 20% from your buying amount
                </div>
                <div className="shead-box2">
                  <p className="multic-head3">Note: 1 TANNIT = 11£</p>
                  <p className="multic-head3" >
                    Profit:<span style={profitStyle}>{profit.toFixed(2)}£</span>
                  </p>
                  <p className="multic-head3">Tax: 12£</p>
                  <p className="multic-head-l">Total selling price: {askingPrice}£</p>
                </div>
                <div className="verification-btns d-flex">
                  <button type="submit" className="btn radio-btn-1 btn-primary" onClick={handleReturn}>Cancel</button>
                  <button type="button" className="btn radio-btn-2 btn-primary" onClick={handleSell} disabled={loading}>
                    {loading ? 'Processing...' : 'Sell'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sellingshare;
