/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconsFontAwesome = ({ icon = "moon", size, iconClassName }) => {
  return (
    <div className={`icons-font-awesome ${size}`}>
      <div className={`icon ${iconClassName}`}>{icon}</div>
    </div>
  );
};

IconsFontAwesome.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf([
    "twenty-four-px",
    "twelve-px",
    "sixteen-px",
    "forty-px",
    "sixty-four-px",
    "fourteen-px",
    "thirty-two-px",
    "twenty-px",
  ]),
};
