/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { BaseSwapThis } from "../BaseSwapThis";
import { ToolTip } from "../ToolTip";
import "./style.css";

export const InstanceTooltip = ({
  stateProp,
  tooltipPosition,
  className,
  override = <BaseSwapThis change="Change instance" />,
  toolTipIndicatorClassName,
  toolTipDirectionTopClassName,
  toolTipTooltipContent = "Information text",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",

    tooltipPosition: tooltipPosition || "top",
  });

  return (
    <div
      className={`instance-tooltip tooltip-position-${state.tooltipPosition} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {override}
      <ToolTip
        className={toolTipDirectionTopClassName}
        direction={
          state.tooltipPosition === "bottom"
            ? "bottom"
            : state.tooltipPosition === "right"
              ? "right"
              : state.tooltipPosition === "left"
                ? "left"
                : "top"
        }
        indicatorClassName={toolTipIndicatorClassName}
        tooltipContent={toolTipTooltipContent}
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

InstanceTooltip.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "default"]),
  tooltipPosition: PropTypes.oneOf(["right", "left", "top", "bottom"]),
  toolTipTooltipContent: PropTypes.string,
};
