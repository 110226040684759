import {React,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../component/authanticationheader";
function TopupSuccess() {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate('/wallet-two');
    };
    useEffect(() => {
        document.title = 'Opertation success'; // Set the page title on component mount
        return () => {
          document.title = 'Tannit'; // Reset the page title when component unmounts
        };
      }, []); // Empty dependency array ensures this effect runs only on mount and unmount
    return (
        <div className="App">
            <Header />
            <div>

                <section className="main-grid2 d-flex">
                    <div className="container">
                        <div className="row">
                            <div className="middle-sec2">
                                <img src="assets/images/flowers.png" alt="" />
                                <h2>Money added successfully!</h2>
                                <p>You have successfully purchased your Tannits. </p>

                            </div>
                            <div className="claim-last-sec2">

                                <button className="btn grn-btn3" onClick={handleGoToDashboard}>
                                    Go to dashboard <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TopupSuccess;
