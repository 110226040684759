import React from 'react';
import Footer from "../../component/footer";
import Header from "../../component/header";
const PrivacyPolicy = () => {
    return (
        <div className="mt-3">
             <Header />
            <div className='container'> 
            <div className="top-banner d-flex justify-content-center flex-wrap align-content-center mb-3">
        <h1 className="banner-hd">Privacy Policy</h1>
        </div>
            <p><strong>Updated on April 9, 2024</strong></p>

            <p>
                Tannit respects the privacy of Internet users and strictly complies with Law No. 78-17 of January 6, 1978, relating to data processing, files, and freedoms, as amended, known as the "Informatique et Libertés" law, and since May 25, 2018, the European General Data Protection Regulation (GDPR) No. 2016/679.
            </p>

            <p>
                We take data protection very seriously and therefore have a dedicated customer service to answer all your questions regarding your aforementioned rights.
            </p>

            <div className="contact-info">
                <p>
                    You can contact us at <a href="mailto:contact@tannit.uk">contact@tannit.uk</a> and indicate "Privacy Policy" in the email subject.
                </p>
            </div>

            <h2>Use of Data</h2>
            <p>
                Data is collected when the User visits the platform, creates an account, or communicates with Tannit after identifying themselves on the Site or providing their identity.
            </p>
            <p>
                The purpose of collecting this data is to allow the User access to the Services available on the Site, propose the opening of an Electronic Wallet, suggest investment products suited to their profile, and enable Tannit to comply with obligations applicable to its crowdfunding service activities.
            </p>

            <h2>Data Retention</h2>
            <p>
                The retention period for personal data depends on the purpose of the processing and the circumstances in which it was collected. It is generally five (5) years due to regulatory obligations applicable to Tannit as a European Crowdfunding Service Provider. However, if the duration of investors' contractual engagement is higher for Collection needs, the retention period may align with this duration. In this case, the five (5) years period starts from the last repayment made within this investment.
            </p>
            <p>
                The collected personal data is not used for purposes other than those mentioned above. Tannit does not market these personal data. All information provided by the User when creating their Profile, except for the username, is protected by a username and password, ensuring that only the User has access to their personal information.
            </p>
            <p>
                Tannit uses personal data for processing the client suitability questionnaire to determine products suited to the Investor's profile in terms of knowledge, risk, and defining investment limits.
            </p>

            <h2>Collected Personal Data</h2>
            <p>
                As a crowdfunding service provider, Tannit collects and stores certain personal data to provide its services. Information is collected when you create an account or fill out the registration form as an Approved Investor or a prospective one.
            </p>
            <p>
                Collected data includes:
            </p>
            <ul>
                <li>Your password</li>
                <li>Your email address</li>
                <li>Your name, first name, date and place of birth</li>
                <li>Postal address, phone number</li>
                <li>Identity and domicile proofs, questionnaire responses, tax preferences and options</li>
                <li>The latest IP address of your identification</li>
            </ul>
            <p>
                For legal entities, the collected information may include the SIRET, the legal entity's address, statutes, KBIS, the Declaration of Beneficial Owners, and valid bank account details opened in France for which the investor is the holder.
            </p>
            <p>
                Online payment data is processed and stored directly by Tannit's Banking Partner and does not transit through Tannit's servers.
            </p>

            <h2>Server Logs</h2>
            <p>
                Server logs (IP addresses and visited pages) are retained for 8 days. Data is used to provide access to services available on the site, suggest the opening of an Electronic Wallet, propose investment products suited to the investor's profile, and comply with obligations applicable to the crowdfunding service activities.
            </p>
            <p>
                Collected personal data is not used for other purposes, and the Company does not market this personal data. The retention period for personal data depends on the processing purpose and the circumstances in which it was collected. It is generally five (5) years due to regulatory obligations applicable to Tannit as a European Crowdfunding Service Provider.
            </p>
            <p>
                Finally, the latest IP address of your identification is used in collaboration with Tannit's internet service provider only if the Company deems it necessary to enforce its Terms of Use or protect its services, customers, or upon request from judicial authorities.
            </p>

            <h2>Sharing of Information</h2>
            <p>
                We take the confidentiality of your information very seriously and do not share your personal data with third parties, except in the cases provided in this privacy policy.
            </p>
            <p>
                However, we are legally obligated to transmit some of your information to third parties in the following circumstances:
            </p>
            <ul>
                <li>To public authorities when required by law;</li>
                <li>To the project owner after a subscription, to comply with legal obligations to maintain a register of issued securities. We transmit your name, first name, and tax address so the project owner can include you in their subscriber register;</li>
                <li>To our payment service provider (PSP), as required by law, to manage your funds securely and verify your identity under Anti-Money Laundering and Counter-Terrorism Financing (AML-CTF). We must therefore communicate your email, name, first name, identity proofs, domicile proofs, and IP address to our Banking Partner, who stores them according to the law. Your bank details are also stored;</li>
                <li>To the company selected for extinctive management (if applicable, the company CapSens), if Tannit disappears;</li>
                <li>To our other third-party service providers, such as server managers, customer service, emailing services, and CRMs, to comply with our regulatory obligations, manage our platform, and improve your experience. We have obtained their privacy policies and data protection policies updated in accordance with the GDPR.</li>
            </ul>
            <p>
                We commit that these third parties will respect the same confidentiality and data protection requirements as those stated in this privacy policy.
            </p>

            <h2>Use of Cookies</h2>
            <p>
                When you browse our website, we store certain messages on your browser called "cookies" to facilitate your use of our platform. Some of these cookies are essential for the proper functioning of our site, such as session cookies that allow you to browse our platform without reconnecting on each new page or authentication cookies that identify you if you come from a partner site.
            </p>
            <p>
                Other non-essential cookies are used to measure the effectiveness of certain marketing actions or improve the platform's ergonomics. We have implemented a system that allows you to refuse the use of these non-essential cookies by clicking on the yellow icon at the bottom left of a navigation page via the Axeptio tool we use.
            </p>

            <h2>User Rights Regarding Collected Personal Data</h2>
            <p>
                As a data subject, you have several rights under national and European data protection regulations. First, you have the right to access your personal data and request information about the processing purposes, categories of personal data processed, recipients or categories of recipients to whom the data has been or will be disclosed, the data retention period, and the rights you have under the regulations.
            </p>
            <p>
                You also have the right to request the data controller to rectify or erase your personal data, or restrict the processing of such data, as well as the right to object to this processing. You can also file a complaint with the competent supervisory authority.
            </p>
            <p>
                It is important to note that providing personal data to Tannit is both contractual and regulatory, and Tannit can only provide its crowdfunding services if you provide this information.
            </p>
            <p>
                Finally, if decisions are made about you based on automated processes, including profiling, you have the right to receive useful information about the underlying logic of this processing and the intended consequences for you.
            </p>
            <p>
                Under applicable data protection legislation, you also have the right to request Tannit to limit the use of your personal data in the following situations:
            </p>
            <ul>
                <li>If you dispute the accuracy of your personal data, you can request that its use be restricted while it is being verified.</li>
                <li>If the processing of your personal data is unlawful, you can request that its use be limited instead of requesting its deletion, if possible.</li>
                <li>If we no longer need your personal data for processing purposes but you still need it to exercise, defend, or establish legal rights, you can request that its use be limited.</li>
                <li>If you object to the processing of your personal data under Article 21 of the GDPR while we verify whether our legitimate grounds override yours, you can request that its use be limited.</li>
            </ul>
            <p>
                When processing is limited, we ensure that your personal data is used only with your consent, to exercise or defend legal rights, to protect another person's rights, or for significant public interest reasons. We will inform you before the limitation is lifted.
            </p>

            <p>
                To exercise these rights, you can contact Tannit by email at: <a href="mailto:contact@tannit.uk">contact@tannit.uk</a>.
            </p>

            <h2>Complaint to a Supervisory Authority</h2>
            <p>
                For any difficulty regarding your personal data, or if you believe Tannit is using your personal data inappropriately, please let us know.
            </p>
        </div>
        <Footer />
        </div>
    );
}

export default PrivacyPolicy;
