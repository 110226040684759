import React from 'react';
import { AuthProvider as OidcAuthProvider } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { setUser, clearUser } from './features/auth/authSlice';
const identityUrl = process.env.REACT_APP_IDENTITY_URL;

const oidcConfig = {
  authority: identityUrl,
  client_id: 'react-client',
  redirect_uri: 'https://tannit.uk/callback',
  responseType: 'code',
  scope: 'openid profile',
  post_logout_redirect_uri: 'https://tannit.uk/callbackLogout',
};

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onSigninCallback = (user) => {
    alert("you are in")
    dispatch(setUser(user));
    alert("User signed in");
  };

  const onSignoutCallback = () => {
    alert("you are out")
    dispatch(clearUser());
    alert("User signed out");
  };

  return (
    <OidcAuthProvider
      {...oidcConfig}
      onSigninCallback={onSigninCallback}
      onSignoutCallback={onSignoutCallback}
    >
      {children}
    </OidcAuthProvider>
  );
};

export default AuthProvider;
