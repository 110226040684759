import React from 'react'

export default function footer() {
  return (
    <div className="footer-wrap">
<footer className="pt-3 pb-3">
  <div className="container footer-section d-flex flex-wrap align-content-between justify-content-center">
    <div className="row pt-5">
      <div className="col-lg-4 col-sm-12 pe-5">
        <img width="167" height="39" src="assets/icons-2/new footer logo.png" alt="img"  />
        <p>Investing in eco-friendly properties is one of the strategies that can provide profitable returns on investment</p>
      </div>
      <div className="col-lg-2 col-sm-12">
        <h3>Information</h3>
        <ul className="list-group2">
          <li className="list-group-item"><a href="/">Welcome</a></li>
          <li className="list-group-item"><a href="/blog">Blog</a></li>
          <li className="list-group-item"><a href="/contact">Contact</a></li>
        </ul>
      </div>
      <div className="col-lg-3 col-sm-12">
        <h3>Our Services</h3>
        <ul className="list-group2">
          <li className="list-group-item"><a href="/properties">Invest</a></li>
          <li className="list-group-item">Buying</li>
        </ul>
      </div>
      <div className="col-lg-3 col-sm-12">
        <h3>Contact Us</h3>
        <ul className="list-group2">
          <li className="list-group-item">2035, Sousse , Sousse</li>
          <li className="list-group-item">info@gmail.com</li>
          <li className="list-group-item">+212 55 564 654</li>
          <li className="list-group-item"> <img width="150" src="assets/icons-2/social.png" alt="img"  /></li>
        </ul>
      </div>
    </div>
    <div className="custom-divider mt-3 mb-3">
    </div>
    <div className="row footer-lt text-center">
      <h3 className="copy-hd">@ 2023 All rights reserved</h3>
      <div class="link-container">
        <a href="/privacy-policy" class="link">Privacy Policy</a>
        <a href="/terms-of-use" class="link">Terms of Use</a>
    </div>
        </div>
  </div>
</footer>
</div>

    );
 }
 
