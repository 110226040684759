/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Carousel = ({ property1 }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className="carouselonboarding"
      onClick={() => {
        dispatch("click");
      }}
    >
      <img
        className="arrow-right"
        alt="Arrow right"
        src="/assets/images/ecovestonboarding/arrow-right-streamline-ultimate-svg-6.svg"
      />

      <img
        className="img"
        alt="Arrow right"
        src="/assets/images/ecovestonboarding/arrow-right-streamline-ultimate-svg-7.svg"
      />

      <div className="overlap-group">
        <div className={`element ${state.property1}`}>
          {state.property1 === "default" && <>01</>}

          {state.property1 === "variant-2" && <>02</>}

          {state.property1 === "variant-3" && <>03</>}

          {state.property1 === "variant-4" && <>04</>}
        </div>

        <div className="frame">
          <div className="join-the-tannit">
            {state.property1 === "default" && <>Join the Tannit Community</>}

            {state.property1 === "variant-2" && <>Invest Green</>}

            {state.property1 === "variant-3" && <>Plant Your Tree</>}

            {state.property1 === "variant-4" && <>Share &amp; Inspire</>}
          </div>

          <div className="sign-up-for-a-free">
            {state.property1 === "default" && (
              <p className="text-wrapper-20">
                Sign up for a free Tannit account and unlock a world of
                sustainable investment opportunities.
              </p>
            )}

            {state.property1 === "variant-2" && (
              <p className="text-wrapper-20">
                Invest in eco-friendly real estate projects and earn valuable
                green Tannit coins as you make a positive impact.
              </p>
            )}

            {state.property1 === "variant-3" && (
              <p className="text-wrapper-20">
                Redeem your green coins to plant virtual trees in our growing
                Tannit Ecovest forest. Each tree represents your commitment to a
                greener future.
              </p>
            )}

            {state.property1 === "variant-4" && (
              <p className="text-wrapper-20">
                Spread the word and inspire others! Share your participation on
                social media and encourage them to join the Tannit Ecovest
                movement.
              </p>
            )}
          </div>
        </div>

        <div className="div">
          <div className={`ellipse property-1-${state.property1}`} />

          <div className={`ellipse-2 property-1-0-${state.property1}`} />

          <div className={`ellipse-3 property-1-1-${state.property1}`} />

          <div className={`ellipse-4 property-1-2-${state.property1}`} />
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "click":
        return {
          property1: "variant-2",
        };
    }
  }

  if (state.property1 === "variant-2") {
    switch (action) {
      case "click":
        return {
          property1: "variant-3",
        };
    }
  }

  if (state.property1 === "variant-3") {
    switch (action) {
      case "click":
        return {
          property1: "variant-4",
        };
    }
  }

  if (state.property1 === "variant-4") {
    switch (action) {
      case "click":
        return {
          property1: "default",
        };
    }
  }

  return state;
}

Carousel.propTypes = {
  property1: PropTypes.oneOf([
    "variant-4",
    "variant-2",
    "variant-3",
    "default",
  ]),
};
