import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddMoney = () => {
  const [selectedTannits, setSelectedTannits] = useState(null);
  const [customTannits, setCustomTannits] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Paypal");
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = user.claims;
  const navigate = useNavigate();

  const handleRadioChange = (value) => {
    setSelectedTannits(value);
    setCustomTannits(""); // Clear custom input when radio is selected
  };

  const handleCustomChange = (e) => {
    setSelectedTannits(null); // Deselect radio buttons
    setCustomTannits(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tannitsTopped = selectedTannits || customTannits;

    const transaction = {
      TannitsTopped: parseInt(tannitsTopped, 10),
      Price: parseFloat(tannitsTopped) * 10,
      CurrencyId: 1,
      Description: "Top-up transaction",
      InvestorId: userId,
      CreatedAt: new Date().toISOString(),
      Vested: false,
    };

    try {
      if (paymentMethod === "Paypal" || paymentMethod === "Stripe") {
        const response = await axios.post(
          `${apiUrl}/Transaction/AddTopupTransaction`,
          transaction
        );
        if (response.status === 201 || response.status === 200) {
          navigate("/topupSuccess");
        } else {
          console.error("Failed to add transaction:", response.data);
        }
      } else if (paymentMethod === "GoCardless") {
        navigate("/topupFailer");
      }
    } catch (error) {
      console.error("Transaction Error:", error);
      navigate("/topupFailer");
    }
  };

  useEffect(() => {
    document.title = "Add Money";
    return () => {
      document.title = "Tannit";
    };
  }, []);

  return (
    <div className="App">
      <section className="main-cont-comp" style={{ maxWidth: "800px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-comp-sec">
              <h3>Add Money to Your Pot: Boost Your Savings with Contributions</h3>
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="mr-1 row d-flex">
                    {/* Tannit Options */}
                    {[
                      { value: 120, price: "1200.00£" },
                      { value: 320, price: "3200.00£" },
                      { value: 1500, price: "15000.00£" },
                    ].map(({ value, price }) => (
                      <div
                        key={value}
                        className={`form-radio-check col ${
                          selectedTannits === value ? "selected" : ""
                        }`}
                        onClick={() => handleRadioChange(value)}
                        style={{
                          cursor: "pointer",
                          border:
                            selectedTannits === value
                              ? "2px solid #007bff"
                              : "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <h4>
                          <span>
                            <img src="assets/icons/Tannit coin.svg" alt="" />
                          </span>
                          {value}
                        </h4>
                        <p>{price}</p>
                      </div>
                    ))}

                    {/* Custom Amount */}
                    <div className="col-12 not-sec">
                      <h5>Custom amount</h5>
                      <p>Amount of TANNIT desired</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <img src="assets/icons/Tannit coin.svg" alt="" />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of TANNIT"
                          value={customTannits}
                          onChange={handleCustomChange}
                          min="1"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            = {customTannits * 10}£
                          </span>
                        </div>
                      </div>
                      <label className="form-label note">
                        Note: 1 TANNIT = 10£
                      </label>
                    </div>

                    {/* Payment Methods */}
                    <div className="col-12 new-radio-2">
                      <h3>Payment method</h3>
                      <div className="mr-1 row d-flex">
                        {["Stripe", "Paypal", "GoCardless"].map((method) => (
                          <div
                            key={method}
                            className={`form-radio-check2 col ${
                              paymentMethod === method ? "selected" : ""
                            }`}
                            onClick={() => setPaymentMethod(method)}
                            style={{
                              cursor: "pointer",
                              border:
                                paymentMethod === method
                                  ? "2px solid #007bff"
                                  : "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <h4>
                              <span>
                                <img
                                  src={`assets/icons/${method.toLowerCase()}.svg`}
                                  alt=""
                                />
                              </span>
                              {method}
                            </h4>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Buttons */}
                    <div className="verification-btns d-flex">
                      <button
                        type="button"
                        className="btn radio-btn-1 btn-primary"
                        onClick={() => navigate(-1)} // Navigate back to the previous page
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn radio-btn-2 btn-primary"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddMoney;
