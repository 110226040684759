import {React,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../component/authanticationheader";

function PurchaseSuccess() {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };
    useEffect(() => {
        document.title = 'Purchase success'; // Set the page title on component mount
        return () => {
          document.title = 'Tannit'; // Reset the page title when component unmounts
        };
      }, []); // Empty dependency array ensures this effect runs only on mount and unmount
    return (
        <div className="App">
            <Header />
            <div>

                <section className="main-grid2 d-flex">
                    <div className="container">
                        <div className="row">
                            <div className="middle-sec2">
                                <img src="assets/images/flowers.png" alt="" />
                                <h2>Purchase was made successfully!</h2>
                                <p>You have successfully invested in the property. </p>

                            </div>
                            <div className="claim-last-sec2">

                                <button className="btn grn-btn3" onClick={handleGoToDashboard}>
                                    Go back to your wallet <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PurchaseSuccess;
