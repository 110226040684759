import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';


function valueLabelFormat(value) {
  const unit = '£';

  let scaledValue = value;

  return `${scaledValue} ${unit}`;
}


export default function PriceSlider() {
  return (
    <Box style={{"width":"100%"}}>
      <Slider defaultValue={0}
        max={5000} 
        aria-label="Default" 
        valueLabelDisplay="auto"         
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}/>
    </Box>
  );
}