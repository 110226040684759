import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const storedProperty = JSON.parse(localStorage.getItem('propertyDetails')) || {};
const storedPropertyInvestors = JSON.parse(localStorage.getItem('propertyInvestors')) || {};
const initialPropertyDetails = storedProperty || null;
const apiUrl = process.env.REACT_APP_API_URL;

export const propertySlice = createSlice({
  name: 'property',
  initialState: {
    propertyDetails: initialPropertyDetails,
    propertyInvestors: storedPropertyInvestors,
    previousProperty: null,
    nextProperty: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    fetchPropertyDetailsStart: (state) => {
      state.status = 'loading';
    },
    fetchPropertyDetailsSuccess: (state, action) => {
      state.status = 'succeeded';
      state.propertyDetails = action.payload.data;
      localStorage.setItem('propertyDetails', JSON.stringify(state.propertyDetails));
    },
    fetchPropertyDetailsFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchPreviousPropertySuccess: (state, action) => {
      state.previousProperty = action.payload.data[0];
    },
    fetchNextPropertySuccess: (state, action) => {
      state.nextProperty = action.payload.data[0];
    },
    fetchPropertyInvestorsStart: (state) => {
      state.status = 'loading';
    },
    fetchPropertyInvestorsSuccess: (state, action) => {
      state.status = 'succeeded';
      state.propertyInvestors = action.payload.data;
      localStorage.setItem('propertyInvestors', JSON.stringify(state.propertyInvestors));
    },
    fetchPropertyInvestorsFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    
    clearPropertyDetails: (state) => {
      state.propertyDetails = null;
      state.propertyInvestors = null;
      state.previousProperty = null;
      state.nextProperty = null;
      state.status = 'idle';
      state.error = null;
    },
  },
});

export const {
  fetchPropertyDetailsStart,
  fetchPropertyDetailsSuccess,
  fetchPropertyDetailsFailure,
  fetchPreviousPropertySuccess,
  fetchPropertyInvestorsStart,
fetchPropertyInvestorsSuccess,
fetchPropertyInvestorsFailure,
  fetchNextPropertySuccess,
  clearPropertyDetails,
} = propertySlice.actions;

export const fetchPropertyDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(fetchPropertyDetailsStart());
    const url = `${apiUrl}/Property/GetPropertyWithTags/${propertyId}`;
    const response = await axios.get(url);
    dispatch(fetchPropertyDetailsSuccess(response.data));
  } catch (err) {
    dispatch(fetchPropertyDetailsFailure(err.toString()));
  }
};

export const fetchPreviousProperty = (currentPropertyId) => async (dispatch) => {
  try {
    const url = `${apiUrl}/Property/GetPreviousProperty/${currentPropertyId}`;
    const response = await axios.get(url);
    dispatch(fetchPreviousPropertySuccess(response.data));
  } catch (err) {
    console.error('Failed to fetch previous property:', err);
  }
};

export const fetchNextProperty = (currentPropertyId) => async (dispatch) => {
  try {
    const url = `${apiUrl}/Property/GetNextProperty/${currentPropertyId}`;
    const response = await axios.get(url);
    dispatch(fetchNextPropertySuccess(response.data));
  } catch (err) {
    console.error('Failed to fetch next property:', err);
  }
};
export const fetchPropertyInvestors = (propertyId) => async (dispatch) => {
  try {
    dispatch(fetchPropertyInvestorsStart());
    const url = `${apiUrl}/Property/GetPropertyInvestors/${propertyId}`;
    const response = await axios.get(url);
    dispatch(fetchPropertyInvestorsSuccess(response.data));
  } catch (err) {
    dispatch(fetchPropertyInvestorsFailure(err.toString()));
  }
};

export default propertySlice.reducer;