import Header from "../../component/dashheader";
import Range from "../../component/priceslider";
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertyDetails, clearPropertyDetails, fetchPropertyInvestors } from '../../features/properties/propertySlice';
import { purchaseProperty } from '../../features/transactions/transactionsSlice';
import { fetchTransactionStats } from '../../features/transactions/transactionsSlice';
import { useNavigate } from 'react-router-dom';


function Purchaseconfirm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const propertyId = searchParams.get('propertyId');
  const [tannitAmount, setTannitAmount] = useState('');
  const [description, setDescription] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Purchase confirm'; // Set the page title on component mount
    return () => {
      document.title = 'Tannit'; // Reset the page title when component unmounts
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount
  const propertyDetails = useSelector((state) => state.property.propertyDetails);
  const propertyInvestorsInt = useSelector((state) => state.property.propertyInvestors);
  const user = useSelector(state => state.auth.user);
  const { id: userId } = user.claims;
  const transactionStats = useSelector(state => state.transactions.transactionStats) || {};
  useEffect(() => {
    dispatch(fetchTransactionStats(userId));
  }, [dispatch]);
  const TotalTannitVestedValue = transactionStats.totalTannitVested || 0;
  const TotalTannitWithdrownValue = transactionStats.totalTannitWithdrown || 0;
  const TotalTannitPurchedValue = transactionStats.totalTannitPurched || 0;
  const TotalTannitnoneVestedValue = transactionStats.totalTannitnoneVested || 0;
  const currentBalance = TotalTannitVestedValue - TotalTannitWithdrownValue - TotalTannitPurchedValue;
  // Calculate the remaining balance after purchase
  const remainingBalance = currentBalance - tannitAmount;

  // Define a variable to hold the message content
  let message = '';
  // Check if the remaining balance is less than the input amount
  if (remainingBalance < 0) {
    message = 'Insufficient balance';
  } else {
    message = '';
  }
  const initialPropertyDetails = {
    propertyId: 0,
    propertyName: '',
    price: 0,
    createdAt: '',
    updatedAt: '',
    archived: false,
    propertyDescription: '',
    propertyTypeId: 0,
    propertyTypeName: '',
    address: {
      addressId: 0,
      latitude: 0,
      longitude: 0,
      streetAddress: '',
      streetAddress2: '',
      streetNumber: 0,
      buildingNumber: '',
      appartNumber: 0,
      city: '',
      state: '',
      countryId: 0,
      postalCode: ''
    },
    images: [],
    tags: []
  };
  const initialPropertyInvestors = {
    propertyId: 0,
    numberOfInvestors: 0,
    propertyPrice: 0,
    totalTannitInvested: 0,

  };
  const sanitizePropertyDetails = (property) => {
    return {
      propertyId: property.propertyId || 0,
      propertyName: property.propertyName || '',
      price: property.price || 0,
      createdAt: property.createdAt || '',
      updatedAt: property.updatedAt || '',
      archived: property.archived || false,
      propertyDescription: property.propertyDescription || '',
      propertyTypeId: property.propertyTypeId || 0,
      propertyTypeName: property.propertyTypeName || '',
      address: {
        addressId: property.address?.addressId || 0,
        latitude: property.address?.latitude || 0,
        longitude: property.address?.longitude || 0,
        streetAddress: property.address?.streetAddress || '',
        streetAddress2: property.address?.streetAddress2 || '',
        streetNumber: property.address?.streetNumber || 0,
        buildingNumber: property.address?.buildingNumber || '',
        appartNumber: property.address?.appartNumber || 0,
        city: property.address?.city || '',
        state: property.address?.state || '',
        countryId: property.address?.countryId || 0,
        postalCode: property.address?.postalCode || ''
      },
      images: property.images || [],
      tags: property.tags || []
    };
  };
  const sanitizePropertyInvestors = (investors) => {
    return {
      propertyId: investors.propertyId || 0,
      numberOfInvestors: investors.numberOfInvestors || 0,
      propertyPrice: investors.propertyPrice || 0,
      totalTannitInvested: investors.totalTannitInvested || 0,
    };
  };

  const propertyInvestors =
    propertyInvestorsInt &&
      propertyInvestorsInt.length > 0 &&
      Array.isArray(propertyInvestorsInt[0]) &&
      propertyInvestorsInt[0].length > 0
      ? sanitizePropertyInvestors(propertyInvestorsInt[0][0])
      : initialPropertyInvestors;

  const status = useSelector((state) => state.property.status);
  const error = useSelector((state) => state.property.error);


  const sanitizedPropertyDetails = sanitizePropertyDetails(
    propertyDetails && propertyDetails.length ? propertyDetails[0] : initialPropertyDetails
  );
  const addressString = `${sanitizedPropertyDetails?.address?.streetAddress || ''}, ${sanitizedPropertyDetails?.address?.city || ''} ${sanitizedPropertyDetails?.address?.postalCode || ''}`;

  const images = sanitizedPropertyDetails.images.map((image) => ({
    type: image.type, // type can be "image" or "video"
    src: image.url,
    alt: "Property Image",
  }));
  const firstImageSrc = images.length > 0 ? images[0].src : ""; // Get the src of the first image

  useEffect(() => {
    dispatch(fetchPropertyDetails(propertyId));
    dispatch(fetchPropertyInvestors(propertyId));

    return () => {
      dispatch(clearPropertyDetails());
    };
  }, [dispatch, propertyId]);
  const price = sanitizedPropertyDetails.price;
  const priceInTannit = price / 10;
  const numberOfInvestors = propertyInvestors.numberOfInvestors;
  const totalTannitInvested = propertyInvestors.totalTannitInvested
  const remainingInvested = priceInTannit - totalTannitInvested;

  
  const progress = ((priceInTannit - remainingInvested) / priceInTannit) * 100;
  const handlePurchase = () => {
    if (currentBalance >= tannitAmount) {
      dispatch(purchaseProperty({
        propertyId,
        investorId: userId,
        AmountTannit: tannitAmount,
        Description: description
      }))
      .then((response) => {
        if (response.payload && response.payload.data && response.payload.data[0]) {
          navigate('/purchaseSuccess');
        } else {
          navigate('/purchaseFailer');
        }
      })
      .catch((error) => {
        console.error('Error during purchase:', error);
        navigate('/purchaseFailer'); // Optionally handle errors by navigating to /purchaseFailer
      });
    } else {
      alert('Insufficient balance');
    }
  };
  const gainTags = sanitizedPropertyDetails.tags.filter(tag => tag.section === "Gain");

  // Find the specific tags for "Target Profitability" and "Returned"
  const profitabilityTag = gainTags.find(tag => tag.label === "Target Profitability");
  const returnedTag = gainTags.find(tag => tag.label === "Returned Income");
  return (

    <div className="App">
      <Header />
      <section className="below-main ps-2">
        <div className="container-fluid artical-cont" style={{ 'maxWidth': '1440px' }}>
          <div className="row artical2 d-flex ">
            <div className="tab-btns2 d-flex  my-1">
            <Link to={`/propertydetail/${propertyId}`}
               className="top-head"><img src="assets/icons/Iconleft.svg" alt="" /> Confirm your purchase
            </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="container payment-first-sec">
        <row className="d-flex flex-md-row flex-column">
          <div className="col-lg-4 img-box-cont"> <img src={firstImageSrc} alt="" /></div>
          <div className="col-lg-8 data-box-cont">

            <div className="left-card2 d-flex">
              <div>
                <h2 className="pay-card-head">{sanitizedPropertyDetails.propertyDescription}</h2>

                <div className="card-inner">
                  <div className="amount">{price} £</div>
                  <div className="remain"> <img src="assets/icons/Tannit coin.svg" alt="" />{remainingInvested} remaining
                  </div>

                </div>
                <div className="card-inner2">
                  <div className="amount-text">Converted to: {priceInTannit} Tannits</div>
                  <div className="investor"> <img src="assets/icons/Icon2.svg" alt="" /> {numberOfInvestors} Investors</div>

                </div>
                <div className="progress main2 rj-pc-page">
                <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>

                </div>

                <div className="card-inner3 my-3 d-flex flex-direction-column">
                  <div className="profit d-flex flex-direction-column">
                    Profitability <img src="/assets/icons/Instance tooltip.png" alt="/" />
                    <p>{profitabilityTag ? `${profitabilityTag.decimalValue}${profitabilityTag.unit}` : 'N/A'}</p>
                  </div>
                  <div className="returned d-flex flex-direction-column">
                    Returned <img src="/assets/icons/Instance tooltip.png" alt="/" />
                    <p>{returnedTag ? `${returnedTag.decimalValue}${returnedTag.unit}` : 'N/A'}</p>
                  </div>



                </div>

              </div>
            </div>
          </div>
        </row>
      </div>


      <div className="cpay-cal" style={{ 'maxWidth': '1010px' }}>
        <h5>Calculator</h5>
        <div className="tab-inner-calculator">

          <div className="row">
            <div className="col-lg-4 calc-tab text-center">
              <h6 className="heading-box">Revenue/month</h6>
              <p className="amount-box">+2,642 £</p>
            </div>
            <div className="col-lg-4 calc-tab2 text-center">
              <h6 className="heading-box">Added value/ year</h6>
              <p className="amount-box">+3,508 £</p>
            </div>
            <div className="col-lg-4 calc-tab3 text-center">
              <h6 className="heading-box">Valuation / year</h6>
              <p className="amount-box">+85,30 £</p>
            </div>
          </div>
          <div className="row2 mt-4">
            <Range></Range>
          </div>


        </div>
        <div className="botm-cal">
          <h3>You have</h3>
          <p><span><img src="assets/icons/Vector wb.svg" alt="" /></span>{currentBalance} TANNIT</p>

        </div>
        <div className="botm-cal2">
          <h3>You will invest</h3>
          <div className="input-group mb-3">
            <span className="input-group-text"><img src="assets/icons/Vector wb.svg" alt="" /></span>
            <input
              type="number"
              value={tannitAmount}
              onChange={(e) => setTannitAmount(e.target.value)}
              className="form-control"
              placeholder="Enter amount in TANNIT"
            />
           <span className="input-group-text">= {tannitAmount * 10}£</span>
                     
          </div>
          <label for="basic-note" className="form-label note">Note: 1 TANNIT = 10£</label>
																	  

          {message && <p className="error-message">{message}</p>}

          <button
        className="btn grn-btn5"
        disabled={tannitAmount > remainingInvested || remainingBalance < 0}
        onClick={handlePurchase}
      >
        Invest
      </button>

        </div>
      </div>

    </div>



  );
};

export default Purchaseconfirm;
