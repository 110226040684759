import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearUser } from './features/auth/authSlice';

const CallbackLogout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogoutCallback = () => {
      dispatch(clearUser());
      window.location.href = '/';
    };

    handleLogoutCallback();
  }, [dispatch]);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
};

export default CallbackLogout;
