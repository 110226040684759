import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PlantingTree = ({ className, image = "/assets/images/ecovestwelcome/image-86.png" }) => {
  return (
    <div className={`planting-tree-wlcm ${className}`}>
      <img className="image" alt="Image" src={image} />

      <div className="text-wrapper">(3)</div>
    </div>
  );
};

PlantingTree.propTypes = {
  image: PropTypes.string,
};
