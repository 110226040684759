/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ToolTip = ({
  tooltipContent = "Information text",
  direction,
  className,
  indicatorClassName,
}) => {
  return (
    <div className={`tool-tip ${className}`}>
      <div className="information-text">{tooltipContent}</div>

      <div className={`indicator ${direction} ${indicatorClassName}`} />
    </div>
  );
};

ToolTip.propTypes = {
  tooltipContent: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "top", "bottom"]),
};
