import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../component/dashheader";
import Graph from "../../component/stat";

function MarketPlaceTwo() {
  const [propertiesGrouped, setPropertiesGrouped] = useState({});
  const base_Url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(base_Url + '/Marketplace', {
      headers: { 'Accept': 'text/plain' },
    })
      .then((response) => {
        if (response.data.status.code === 1) {
          const properties = response.data.data[0];
          const grouped = properties.reduce((acc, property) => {
            if (!acc[property.propertyId]) {
              acc[property.propertyId] = [];
            }
            acc[property.propertyId].push(property);
            return acc;
          }, {});

          setPropertiesGrouped(grouped);
        }
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
      });
  }, [base_Url]);

  const handleBuyClick = (property) => {
    localStorage.setItem('purchaseDetails', JSON.stringify({
      ForSaleId: property.id,
      InvestorId: property.investorId,
      PropertyId: property.propertyId,
      Shares: property.amountTannit,
      sellerId: property.investorId,
      SellerFullName: property.sellerFullName,
      AmountTannit: property.askingPrice,
    }));
    window.location.href = '/buyproperty';
  };

  return (
    <div className="App">
      <Header />
      <section>
        <div className="container-fluid artical-cont">
          {Object.keys(propertiesGrouped).length > 0 ? (
            Object.entries(propertiesGrouped).map(([propertyId, propertyList]) => (
              <div key={propertyId} className="container-fluid main-mpv2-cont mt-3">
                <div className="row mvp-r1 align-items-center">
                  <div className="col-12 col-sm-4 col-md-3 col-lg-2 p-0 mb-3 mb-sm-0">
                    <img className="img-fluid rounded-3" src={propertyList[0].image.url} alt="Property" />
                  </div>
                  <div className="col-12 col-sm-4 col-md-5 col-lg-5">
                    <h3 className="mb-2">{propertyList[0].property.propertyName}</h3>
                    <p className="card-text">
                      <span><img src="assets/icons/gb 1.svg" className="me-2" alt="Location" /></span>
                      {`${propertyList[0].property.address.streetAddress}, ${propertyList[0].property.address.city} ${propertyList[0].property.address.postalCode}`}
                    </p>
                  </div>
                  <div className="stat col-12 col-sm-2 col-md-3 col-lg-2">
                    <Graph />
                  </div>
                  <div className="col-12 col-sm-2 mv2-btn col-md-2 d-flex col-lg-2 justify-content-sm-end">
                    <a href='#'>
                      Property details <span><img src="assets/icons/Fontexit2.svg" alt="Details" /></span>
                    </a>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th className='datehcolumn px-0'>Date Joined</th>
                            <th className='sellerhcolumn px-0'>Seller</th>
                            <th className='px-0'>For Sale</th>
                            <th className='px-0'>Variation</th>
                            <th className='px-0'>Asking Price</th>
                            <th className='ps-0'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {propertyList.map((property) => (
                            <tr
                              key={property.id}
                              onClick={() => handleBuyClick(property)}
                              style={{ cursor: 'pointer' }}
                              className="property-row"
                            >
                              <td className='datecolumn px-0'>{new Date(property.timeStamp).toLocaleDateString()}</td>
                              <td className='sellercolumn px-0'>
                                <span>
                                  <img src="assets/images/Ellipse 231.png" className="me-2" alt="Seller" />
                                </span>
                                {property.sellerFullName.length > 1
                                  ? `${property.sellerFullName[0]}***${property.sellerFullName[property.sellerFullName.length - 1]}`
                                  : property.sellerFullName}
                              </td> 
                              <td className="img-text-td2 px-0">
                                {property.amountTannit} <span><img src="assets/images/Tannit coin.png" alt="Tannit Coin" /></span>
                              </td>
                              <td className='px-0'>
                                {property.sellingPercentage >= 0
                                  ? `+${property.sellingPercentage}%`
                                  : `${property.sellingPercentage}%`}
                              </td>
                              <td className='px-0'>{property.askingPrice}£</td>
                              <td className="img-text-td2 ps-0">
                                <button
                                  className="view-btn btn-sm buy-btn"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents row click event from firing
                                    handleBuyClick(property);
                                  }}
                                >
                                  Buy <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-5">No properties available</div>
          )}
        </div>
      </section>
    </div>
  );
}

export default MarketPlaceTwo;
