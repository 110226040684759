import {React,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../component/authanticationheader";

function TopupFailer() {
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate('/wallet-two');
    };

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };
    useEffect(() => {
        document.title = 'Operation failed'; // Set the page title on component mount
        return () => {
          document.title = 'Tannit'; // Reset the page title when component unmounts
        };
      }, []); // Empty dependency array ensures this effect runs only on mount and unmount
    return (
        <div className="App">
            <Header />
            <div>

                <section className="main-grid2 d-flex">
                    <div className="container">
                        <div className="row">
                            <div className="middle-sec2">
                                <img src="assets/images/failer.png" alt="" />
                                <h2>Operation failed!</h2>
                                <p>Something went wrong, please contact customer support if you need any help. </p>

                            </div>
                            <div className="claim-last-sec2">
                                <button className="btn grn-btn3" onClick={handleTryAgain}>Try again<i className="fa-solid fa-arrow-right"></i></button>
                                <button className="btn grn-btn3" onClick={handleGoToDashboard}>Go to dashboard<i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TopupFailer;
