import React from "react";
import { PlantingTree } from "../../component/ecovestwelcome/PlantingTree";
import "./style.css";
import { useNavigate } from 'react-router-dom';

const EarnXp = () => {
  const navigate = useNavigate();
  return (
    <div className="earn-XP">
      <div className="div">
        <button className="button" onClick={() => navigate(-1)}>
        <i className="fa-solid fa-xmark icons-font-awesome-instance"></i>
        </button>

        <div className="table-row">
          <div className="frame" />

          <div className="frame-2">
            <div className="user">
              <div className="text-wrapper">Gretchen Stanton</div>

              <div className="div-wrapper">
                <div className="text-wrapper-2">12 trees</div>
              </div>
            </div>

            <div className="text-wrapper-3">Grow more trees.</div>
          </div>
        </div>

        <div className="objectives-wrapper">
          <div className="objectives">OBJECTIVES</div>
        </div>

        <div className="container p-0">
          <div className="container-2">
            <div className="text-container">
              <div className="title">COMPLETE WEEKLY OBJECTIVES</div>

              <p className="description">
                Tackle all the bonus objectives set for this week by TANNIT.
                Earn a massive EXP boost and become a top contributor!
              </p>
            </div>

            <div className="progress">0/5</div>

            <div className="XP-container">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestearnxp/green-coin.svg"
              />

              <div className="XP-text">1000 XP</div>
            </div>
          </div>

          <div className="container-2">
            <div className="text-container">
              <div className="title">FRIEND FROM THE FOREST</div>

              <p className="description">
                Invite 2 friends to join TANNIT this week. Expand the TANNIT
                community and grow together!
              </p>
            </div>

            <div className="progress">0/2</div>

            <div className="XP-container">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestearnxp/green-coin-1.svg"
              />

              <div className="XP-text">2000 XP</div>
            </div>
          </div>

          <div className="container-2">
            <div className="text-container">
              <div className="title">FRIEND FROM THE FOREST</div>

              <p className="description">
                Share your TANNIT experience on social media. Spread the word
                and inspire others to join the movement!
              </p>
            </div>

            <div className="right-icon">
              <button className="button-2">Share now</button>
            </div>

            <div className="XP-container">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestearnxp/green-coin-2.svg"
              />

              <div className="XP-text">1000 XP</div>
            </div>
          </div>

          <div className="container-2">
            <div className="text-container">
              <div className="title">GREEN THUMB GURU</div>

              <p className="description">
                Answer 3 trivia questions correctly about tree species or
                environmental benefits. Test your knowledge and become a tree
                expert!
              </p>
            </div>

            <div className="right-icon">
              <button className="button-2">Let’s go</button>
            </div>

            <div className="XP-container">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestearnxp/green-coin-3.svg"
              />

              <div className="XP-text">1000 XP</div>
            </div>
          </div>

          <div className="container-2">
            <div className="text-container">
              <div className="title">SPROUTING SPREE</div>

              <p className="description">
                Plant 3 trees this week and watch your forest flourish!
              </p>
            </div>

            <div className="progress">0/3</div>

            <div className="XP-container">
              <img
                className="green-coin"
                alt="Green coin"
                src="/assets/images/ecovestearnxp/green-coin-4.svg"
              />

              <div className="XP-text">1000 XP</div>
            </div>
          </div>
        </div>

        <div className="note">
          <div className="XP-container-2">
            <img className="img" alt="Green coin" src="/assets/images/ecovestearnxp/green-coin-5.svg" />

            <div className="XP-text-2">1000 XP</div>
          </div>

          <div className="title-2">=</div>

          <PlantingTree
            className="planting-tree-available-trees"
            divClassName="planting-tree-instance"
            image="/assets/images/ecovestwelcomeimg/image-86-1.png"
            text="(1)"
          />
        </div>
      </div>
    </div>
  );
};
export default EarnXp;