    import React , { useState , useEffect } from 'react';
    import Header from "../../component/dashheader";
    import Pricerange from '../../component/price-range';
    import { useSelector } from 'react-redux';
    import axios from 'axios';
    
    function Properties() {
        const [property,setproperty] = useState([]);
        const [pagination,setpagination] = useState(6);
        const [propertyCount, setPropertyCount] = useState(0);
        const [myPropertyCount, setMyPropertyCount] = useState(0);
        const [propertyListCount, setPropertyListCount] = useState(0);
        const [loading, setLoading] = useState(false);
        const base_Url = process.env.REACT_APP_API_URL;
        const user = useSelector(state => state.auth.user);
        const { id } = user.claims || {};
        const investorId = id && id.toString();
    
        useEffect(() => {
            const fetchProperties = async () => {
                setLoading(true);
                try {
                  const response = await axios.get(base_Url + '/Property/Properties?start=0&pageSize=' + pagination + '&investorId=' + investorId);
                  setproperty(response.data.data[0]);
                  setPropertyListCount(response.data.data[0].length);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
                finally {
                    setLoading(false);
                }
              };
              fetchProperties();
              const fetchPropertiesCount = async () => {
                setLoading(true);
                try {
                  const count = await axios.get(base_Url + '/Property/Count');
                  setPropertyCount(count.data.data[0]);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
                finally {
                    setLoading(false);
                }
              };
              fetchPropertiesCount();
              const fetchMyPropertiesCount = async () => {
                setLoading(true);
                try {
                  const count = await axios.get(base_Url + '/Property/Count/' + investorId);
                  setMyPropertyCount(count.data.data[0]);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
                finally {
                    setLoading(false);
                }
              };
              fetchMyPropertiesCount();
        },[pagination]);
    
    
        const handleloadmore = () => {
            setpagination(prevPagination => prevPagination + 3);
        };

        return (
        <div className="App">
        <Header /> 
        <section>


    <div className="container-fluid artical-cont "  style={{'maxWidth': '1440px'}}>
        <div className="row artical1">
            <div className="tab-btns d-flex justify-content-center my-4 gap-2"> 
                <a href='/Properties' className="btn-5 p-tab">{'Properties (' + propertyCount + ')'}</a>
                <a href='/Myinvestment' className="btn-5 p-tab2">{'My investments (' + myPropertyCount + ')'}</a>
            </div>
            <div className="tf-sec d-flex flex-lg-row flex-column gap-3 mb-3">
            <div className="tab-container">
                <div className="tab d-flex flex-row gap-3">
                    <div className="btn-group new-dropdown-btn">
                        <button className="tablinks tab" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Price range<span><img src="assets/icons/1.svg" alt="" /></span>
                        </button>
                        <div className="dropdown-menu px-3" aria-labelledby="dropdownMenuButton" style={{'width':'250px'}}>
                            <Pricerange />
                        </div>
                    </div>
                        <div className="btn-group new-dropdown-btn">
                            <button className="btn btn-secondary ndb1 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Type
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                            </ul>
                        </div>
                            <div className="btn-group new-dropdown-btn">
                            <button className="btn btn-secondary ndb1 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Region
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                            </ul>
                            </div>
                            <div className="btn-group new-dropdown-btn">
                            <button className="btn btn-secondary ndb1 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Energy
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                                <li><a className="dropdown-item" href="/">Menu item</a></li>
                            </ul>
                            </div>
                    <button className="tablinks tab">Advanced filter<span><img src="assets/icons/5.svg" alt="" /></span></button>
                </div>
            </div>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search for property" aria-label="Input group example" aria-describedby="basic-addon1" />
                    <span className="input-group-text" id="basic-addon1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path>
                        </svg>
                    </span>
                </div>
            </div>
            <div className="row d-flex gap-3 p-4">
                <div className="m-text1 w-auto">Applied filter :</div>
                <div className="m-text w-auto">United Kingdom <img src="assets/icons/Icon.svg" alt="" /></div>
                <div className="m-text w-auto">Price : 12k € - 320 € <img src="assets/icons/Icon.svg" alt="" /></div>
                <div className="m-text w-auto">Price : 12k € - 320 € <img src="assets/icons/Icon.svg" alt="" /></div>
                <div className="m-text-i w-auto"><img src="assets/icons/Button.svg" alt="" /></div>

            </div>
            <div>
                <div>
                    <div className="result-text d-flex spb-btn">
                        <p>Showing <strong>{propertyCount} {propertyCount > 1 ? 'properties' : 'property'}</strong> that match your search criteria</p>
                        <button className="mp-btn">Sort by (Date)<span><img src="assets/icons/updown.svg" alt="" /></span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-5">
        {
        property.map((item,key) => ( 
            <div key={key} className="col">
                <div className="card h-100">
                    <div id={'image' + item.propertyId} className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {item.images.map((image, imageIndex) => (
                                <button
                                    key={imageIndex}
                                    type="button"
                                    data-bs-target={'#image' + item.propertyId}
                                    data-bs-slide-to={imageIndex}
                                    className={imageIndex === 0 ? "active" : ""}
                                    aria-current={imageIndex === 0 ? "true" : "false"}
                                    aria-label={'Slide ' + image.imageId}>
                                </button>
                            ))}
                        </div>
                        <div className="carousel-inner">
                            {item.images.map((image, imageIndex) => (
                                <div
                                    key={imageIndex}
                                    className={`carousel-item ${imageIndex === 0 ? "active" : ""}`}>
                                    {image.type == 'image' ? <img src={image.url} className="d-block w-100 propertyImage" style={{'border-radius':'16px 16px 0px 0px'}} alt="..." />
                                     : <video className="d-block w-100 propertyImage" controls style={{'border-radius':'16px 16px 0px 0px'}}>
                                     <source src={image.url} type="video/mp4" /></video>}
                                    
                                    <button className="image-btn">
                                        <img src="assets/icons/leaf.svg" alt="" />Eco Friendly
                                    </button>
                                    <button className="image-btn-4">
                                        <img src="assets/icons/hleaf.svg" alt="" />125 <img src="assets/icons/up.svg " alt="" />0.2%
                                    </button>
                                </div>
                            ))}
                        </div>
                </div>



                    <div className="card-body">
                        <div className="2btn-grp">
                        {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId != 16 && tag.tagId != 17) ? 'd-none' : 'tag'}>
                                {(tag.tagId == 16 || tag.tagId == 17) ? <button className="global-ct">{tag.label}</button> : <div className='d-none'></div>}
                            </div>
                        ))}
                        {item.investorPropertyId > 0 ?
                            <button className="global-ct-sec"><span> <img src="assets/icons/grn-tick.svg" alt="" /> </span> Contributed</button>
                            :
                            ''
                        }
                    </div>
                        <h5 className="p-card-title">{item.propertyName}</h5>
                        <div className='d-flex align-items-center mb-3'>
                        <img src="assets/icons/gb 1.svg" alt="" className='me-2' />
                        <p className="card-text">
                            {item.address.streetAddress + ', ' + item.address.city + ' ' + item.address.postalCode}
                        </p>
                        </div>
                        <div className='d-flex'>
                        {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId < 11 && tag.tagId > 15) ? 'd-none' : 'tag'}>
                                {(tag.tagId >= 11 && tag.tagId <= 15) ? <button className="inline-svg-icons-btn1 me-2"><i className={tag.fontAwesomeId}></i></button> : <div className='d-none'></div>}
                            </div>
                        ))}
                        </div>
                        <div>
                            <div className="card-inner">
                                <div className="amount">{(item.price / 1000) + 'K £'}</div>
                                <div className="remain"> <img src="assets/icons/Tannit coin.svg" alt="" />{((item.price /10) - item.tannitInvested) + ' remaining'}
                                </div>

                            </div>
                            <div className="card-inner2">
                                <div className="amount-text">{'Converted to: ' + (item.price /10) + ' Tannits'}</div>
                                <div className="investor"> <img src="assets/icons/Icon2.svg" alt="" />{item.numberOfInvestors + ' Investors'}</div>

                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{'width': ((((item.price / 10) - ((item.price /10) - item.tannitInvested)) / (item.price / 10)) * 100) + '%'}} aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="card-inner3 my-4 d-flex flex-direction-column gap-2">
                            {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId != 4 && tag.tagId != 6) ? 'd-none' : 'tag'}>
                                {tag.tagId == 4 ? 
                                    <div className="profit d-flex flex-direction-column gap-1 w-100">
                                        <span>Profitability</span> 
                                        <i class={tag.fontAwesomeId}></i>
                                        <p>{tag.decimalValue + '%'}</p>
                                    </div>
                                 : <div className='d-none'></div>}
                                {tag.tagId == 6 ? 
                                    <div className="returned d-flex flex-direction-column gap-1 w-100">
                                        <span>Returned</span>
                                        <i class={tag.fontAwesomeId}></i>
                                        <p>{tag.decimalValue + '%'}</p>
                                    </div>
                                 : <div className='d-none'></div>}
                            </div>
                            ))}
                            </div>
                            <div>
                                <a href={'propertydetail/' + item.propertyId} className="view-btn w-100">{((item.price /10) - item.tannitInvested) !== 0 ? 'Invest ' : 'Details '}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ))
        }
        </div>
        <div className="row justify-content-center mb-5">
            {propertyListCount < propertyCount?
                <button onClick={handleloadmore} className="bw-learn btn btn-show" type="search" disabled={loading}>
                    {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                        'Show more'
                    )}
                </button>
                :<div></div>
            }    
            </div>
    </div>


    </section>

        </div>

        
    
        );
    }
    
    export default Properties;