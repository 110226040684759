// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import transactionsReducer from './features/transactions/transactionsSlice';
import propertyReducer from './features/properties/propertySlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    transactions: transactionsReducer,
    property: propertyReducer,
  },
});

export default store;
